@import '~@bokbasen/react-style-core/scss/common/helpers';

@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(-2rem);
    }
}

.fulltext-results {
    margin-bottom: $spacing;
    margin-top: $spacing;
    border: none;
}

.fulltext-results__item {
    animation: fade-in 150ms $ease-out-quart backwards;
    @include animation-delay(24, 30ms, 60ms);
    @include font-size(small);
    background: $color-light;
    padding: $spacing--small $spacing--medium;
    margin-bottom: 1px;

    &:first-child {
        border-top-left-radius: $border-radius--small;
        border-top-right-radius: $border-radius--small;
    }

    &:last-child {
        border-bottom-left-radius: $border-radius--small;
        border-bottom-right-radius: $border-radius--small;
    }
}

.fulltext-results__item--link {
    padding: 0;
}

.fulltext-results__item mark:not([class]) {
    display: inline-block;
    line-height: 1;
    background-color: color(secondary);
    border: 0 solid color(secondary);
    border-radius: 60px 5px / 5px 45px;
    border-width: 0.075em 0;
}

.fulltext-results__link {
    color: $color-text;
    padding: $spacing--medium $spacing--medium;
    padding-right: $spacing--large;
    position: relative;
    display: block;
    &:hover, &:focus {
        text-decoration: underline;
    }
}

.fulltext-results__arrow-icon {
    position: absolute;
    top: $spacing--medium;
    right: $spacing--small;
}
