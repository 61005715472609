@import '~@bokbasen/react-style-core/scss/common/helpers';

.accountemail__preamble {
  @include font(default, base);
  @include font-size(medium);
  color: $color-text;
  margin: 0 0 $spacing--large 0;
}


.accountemail__skip--link {
  margin-left: $spacing--large;
}