@import '~@bokbasen/react-style-core/scss/common/helpers';

.simple-search__alternative {
    color: $color-light;
    margin-top: $spacing;
    @include min-width(md) {
        @include font-size(medium);
    }

    a {
        color: color(secondary)!important;
    }
}
