@import '~@bokbasen/react-style-core/scss/common/helpers';

.mybooks {
    padding: $spacing--large 0;
    position: relative;

    @include min-width(lg) {
        padding: $spacing--xlarge 0;
    }

    & > * {
        animation: lp-opacity 500ms $ease-out-quart both;
        @include animation-delay(5, 50ms, 100ms);
    }
}

.mybooks figure {
    margin: 0 0 $spacing--large;
}

.mybooks p {
    @include font-size(medium);
    color: $color-text;
}

.mybooks__parent {
    position: relative;
    margin-bottom: $spacing;
    left: -($spacing--small);

    @include min-width(xl) {
        position: absolute;
        left: $spacing;
        top: $spacing--xlarge;
    }
}

@keyframes lp-opacity {
    from {
        opacity: 0;
    }
}

.mybooks__note {
    display: flex;
    align-items: center;
    padding: $spacing--small  $spacing--xsmall;
    border: 1px solid color(neutral, 10);
    background: color(neutral, 10);
    color: $color-text;
    border-radius: $border-radius;
    @include font-size(small);
    margin-bottom: $spacing--medium;
    animation: bounce-in 300ms forwards;
    width: 100%;

    @include min-width(md) {
        @include font-size(small);
    }

    & svg {
        margin-right: $spacing--small;
        min-width: 10px;
    }

    a {
        display: inline-flex;
    }

    button {
        margin-left: $spacing--small;
    }

    button + button {
        margin-left: $spacing--xsmall;
    }
}

.mybooks__note--transparent {
    border: none;
    background: transparent;
    padding: 0;
    margin-bottom: $spacing;

    svg {
        fill: color(primary);
    }
}
