@import '~@bokbasen/react-style-core/scss/common/helpers';

.reader-styles :global {

    .font-family-option {
        display: block;
        width: 100%;
        text-align: left;
        border-radius: $border-radius--small;
        padding: $spacing--small;

        &:hover,
        &:focus {
            background-color: color(neutral,10);
        }

        &.active {
            font-weight: bold;
            color: color(primary);
        }

    }

}

body[data-viewer-theme="sepia"] .reader-styles :global {
    .font-family-option {
        &:hover,
        &:focus {
            background-color: darken($color-sepia,5%);
        }
    }
}

body[data-viewer-theme="night"] .reader-styles :global {
    .font-family-option {
        color: $color-light;
        &:hover,
        &:focus {
            background-color: color(neutral,base);
        }

        &.active {
            color: color(secondary);
        }
    }
}