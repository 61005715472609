@import '~@bokbasen/react-style-core/scss/common/helpers';

.textarea-group {
    display: block;
    background: $color-light;
    border-radius: $border-radius;
    border: $border-width solid transparent;
    transition: border 300ms $ease-out-expo;
    width: 100%;
    &:focus-within {
        border-color: $color-focus;
    }
}

.textarea-group--subtle {
    background: color(neutral, 10);

    textarea {
        border: none;
        background: color(neutral, 10);
    }
}


.textarea-group__button {
    display: block;
    padding: $spacing--small;
    padding-top: 0;
}

.textarea-group--small {

    & .textarea-group__button {
        padding: $spacing--small;
    }
}