@import '~@bokbasen/react-style-core/scss/common/helpers';

$arrow-size: 0.4rem;

.button-icon {
    @include font(default, medium);
    display: inline-block;
    position: relative;
    border-radius: $border-radius;
    padding: $spacing--medium;
    border: $border-width solid transparent;
    color: $color-text;
    background-color: transparent;
    transition: all 300ms $ease-out-quart, color 300ms $ease-out-quart;
    line-height: 1;
    vertical-align: bottom;
    text-align: center;
    &:hover,
    &:focus {
        background-color: color(neutral,5);
        background-color: $color-neutral-5-transparent;
        .button-icon__tooltip {
            display: block;
        }
    }
    &:hover .button-icon__tooltip {
        z-index: $level-3; /* Hover tooltips should be above focused tooltips */
    }
    &:focus {
        outline: none;
        border: 1px solid $color-border;
    }
    &:active {
        border-color: transparent;
    }
    svg {
        display: block;
        margin: 0 auto;
        fill: color(primary);
    }
}

.button-icon--small {
    padding: $spacing--xsmall;
    min-width: 26px;
    min-height: 26px;
    border-radius: $border-radius--small;

    .button-icon__tooltip {
        bottom: $spacing--large + $spacing--medium;
    }

    .button-icon--flip-label .button-icon__tooltip {
        top: $spacing--large + $spacing--medium;
    }
}

.button-icon--large {
    padding: $spacing;
    border-radius: $border-radius;
}

.button-icon--light {
    &:hover,
    &:focus {
        background-color: color(neutral, 80);
    }
    svg {
        fill: $color-light;
    }
}

.button-icon--subtle {
    color: $color-text;
    background-color: transparent;
    border-color: rgba($color-text, 0.2);

    &:hover,
    &:focus {
        background: none;
        border-color: rgba($color-text, 0.5);
    }
}

.button-icon__tooltip {
    display: none;
    background: color(neutral, base);
    padding: $spacing--small  $spacing--medium;
    border-radius: $border-radius--small;
    position: absolute;
    z-index: $level-2;
    left: 50%;
    white-space: nowrap;
    bottom: $spacing--large * 2;
    transform: translateX(-50%);
    animation: marker-tooltip-content-in 600ms $ease-out-expo both;
    animation-delay: 0.25s;
    transform-origin: bottom;
    color: $color-light;
    outline: none;
    @include font(default);
    @include font-size(xsmall);

    &:before {
        content: '';
        position: absolute;
        width: $arrow-size;
        height: $arrow-size;
        bottom: -($arrow-size / 2);
        left: calc(50% - #{$arrow-size / 2});
        background-color: inherit;
        transform: rotate(45deg);
    }
}

.button-icon__color {
    display: inline-block;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    background: color(neutral,20);
    border: 2px solid $color-light;
    margin-top: 3px; /* TODO: Browser check this bug */
    @include min-width(lg) {
        width: 18px;
        height: 18px;
    }
}

.button-icon--color-preview {
    position: relative;
}

.button-icon--color-preview.button-icon--small {
    padding: $spacing--xsmall;
}

.button-icon--flip-label .button-icon__tooltip {
    transform-origin: top;
    bottom: auto;
    top: $spacing--large * 2;
    &:before {
        bottom: auto;
        top: -($arrow-size / 2);
        transform: rotate(135deg);
    }
}

.button-icon--right-label .button-icon__tooltip {
    left: 0;
    transform: translateX(0%);
    animation: marker-tooltip-content-in-left-right 600ms $ease-out-expo both;
    &:before {
        left: 1.5rem;
    }
}

.button-icon--left-label .button-icon__tooltip {
    left: auto;
    right: 0px;
    transform: translateX(0%);
    animation: marker-tooltip-content-in-left-right 600ms $ease-out-expo both;
    &:before {
        left: auto;
        right: 1.5rem;
    }
}

.button-icon--disable-label .button-icon__tooltip {
    display: none !important;
}

.button-icon--small .button-icon__tooltip {
    bottom: $spacing * 2;
}

.button-icon--small.button-icon--flip-label .button-icon__tooltip {
    top: $spacing * 2;
}

.button-icon--small.button-icon--right-label .button-icon__tooltip {
    &:before {
        left: 0.75rem;
    }
}

.button-icon--small.button-icon--left-label .button-icon__tooltip {
    &:before {
        right: 0.75rem;
    }
}

body[data-viewer-theme="sepia"] .button-icon--appearance-styling {
    &:hover,
    &:focus {
        background-color: darken($color-sepia, 5%);
    }
    &.button-icon--light {
        &:hover,
        &:focus {
            background-color: color(neutral, 80);
        }
    }
}

body[data-viewer-theme="night"] .button-icon--appearance-styling {
    &:hover,
    &:focus {
        background-color: color(neutral,base);
    }
    svg {
        fill: $color-light;
    }
    .button-icon__tooltip {
        background: $color-light;
        color: $color-text;
    }
    &.button-icon--light {
        &:hover,
        &:focus {
            background-color: color(neutral,10);
        }
        svg {
            fill: $color-text;
        }
    }
    &.button-icon--subtle {
        border-color: rgba($color-light, 0.2);
        &:hover,
        &:focus {
            border-color: rgba($color-light, 0.5);
        }
    }
    .button-icon__color {
        border-color: color(neutral,80);
    }
}

@keyframes marker-tooltip-content-in {
    from {
        opacity: 0;
        transform: translateY(0.5rem)  translateX(-50%)  scale(0.5) ;
    }
}

@keyframes marker-tooltip-content-in-left-right {
    from {
        opacity: 0;
        transform: translateY(0.5rem)  translateX(0%)  scale(0.5) ;
    }
}
