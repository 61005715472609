@import '~@bokbasen/react-style-core/scss/common/helpers';

.reader-styles :global {
    .text-popup {
        background-color: white;
        border-radius: 5px;
        color: black;
        font-family: inherit !important;
        font-size: inherit !important;
        padding: 10px;
        filter: drop-shadow(0 0 5px black);
        position: absolute;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.2s, opacity 0.2s linear;
        transform-origin: bottom;
        transform: translate(-50%, -100%);

        &:before {
            $size: 1rem;
            content: '';
            position: absolute;
            width: $size;
            height: $size;
            bottom: -($size / 2);
            left: calc(50% - #{$size / 2});
            background-color: inherit;
            transform: rotate(45deg);
        }

        &.visible {
          visibility: visible;
          opacity: 1;
        }

        max-width: 150px;
        
        @include min-width(md) {
          max-width: 250px;
        }
        @include min-width(lg) {
          max-width: 300px;
        }

    }

    // Used as a background which removes the popup when clicked
    .text-popup-close {
        display: none;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
    }
}