@import '~@bokbasen/react-style-core/scss/common/helpers';

.book-details-page {
  position: relative;
  overflow: hidden;
  padding: $spacing--xlarge 0 0;
}

.book-details-page__primary {
}

.book-details-page__secondary {

  & > * {
    animation: bd-opacity 500ms $ease-out-quart both;
    @include animation-delay(5, 50ms, 100ms);
  }
}

.book-details-page__parent {
  position: relative;

  @include min-width(lg) {
    position: absolute;
    left: $spacing;
    top: $spacing--xlarge;
  }
}

.book-details-page__related {
  position: relative;
  padding: $spacing--large 0;
  background-color: $color-light;
  background-color: color(neutral, 10);

  @include min-width(md) {
    padding: $spacing--xlarge 0;
  }
}

.book-details-page__related + .book-details-page__related {
  padding-top: $spacing--large;

  @include min-width(md) {
    padding-top: 0;
  }
}

.book-details {
  display: flex;
  margin-bottom: $spacing--xlarge;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  @include min-width(lg) {
    flex-wrap: nowrap;
  }
}

.book-details__image-wrapper {
  flex-basis: 100%;
  max-width: 100%;
  order: 1;
  //background: $color-light;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: $spacing;
  align-items: center;

  animation: bd-image 300ms $ease-out-quart both;
  animation-delay: 100ms;

  @include min-width(sm) {
    align-items: flex-start;
    flex-basis: 25%;
    max-width: 25%;
  }

  @include min-width(lg) {
    flex-basis: 25%;
    max-width: 25%;
  }
}

.book-details__image {
  //box-shadow: $shadow--light;
  @include min-width(sm) {
    width: 100%;
    padding-right: $spacing--large;
  }

  img {
    width: 60%;
    min-width: 96px;
    margin: 0 auto;
    box-shadow: 0 24px 60px -10px rgba(22, 36, 58, 0.3);
    border-radius: 2px;
    background: $color-light;
    @include min-width(sm) {
      width: 100%;
      border-radius: 4px;
    }
  }
}


.book-details__content {
  flex-basis: 100%;
  max-width: 100%;
  order: 2;

  @include min-width(sm) {
    flex-basis: 75%;
    max-width: 75%;
  }

  @include min-width(lg) {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 $spacing--large+$spacing 0 0;
  }

  & > * {
    animation: bd-content 300ms $ease-out-quart both;
    @include animation-delay(5, 40ms, 0ms);
  }
}


.book-details__cta {
  flex-basis: 100%;
  width: 100%;
  margin-top: $spacing--large;
  margin-bottom: $spacing--large;
  order: 3;

  @include min-width(lg) {
    position: absolute;
    right: 0;
    top: 0;
    flex-basis: 25%;
    width: 25%;
    margin: 0;
  }
}

.book-details__title {
  @include font(default, bold);
  @include font-size(large);
  color: color(primary);
  margin: 0 0 $spacing--small;
  text-align: center;
  @include font-size(large);

  @include min-width(sm) {
    text-align: left;
  }
}

.book-details__authors {
  @include font-size(base);
  margin-bottom: $spacing--large;
  text-align: center;

  @include min-width(sm) {
    @include font-size(medium);
    text-align: left;
  }

  svg {
    fill: color(neutral, base);
  }
}

.book-details__author-link {
  color: $color-text;
  border-bottom: 1px dashed color(neutral, 50);

  &:hover, &:focus {
    cursor: pointer;
    color: $color-links--hover;
    border-bottom-color: $color-links--hover;
  }
}

.book-details__author + .book-details__author {
  margin-left: $spacing--medium;
}

.book-details__year-published {
  margin-left: $spacing--medium;
}

.book-details__category {
  @include font(default);
  @include font-size(base);

  @include min-width(lg) {
    @include font-size(medium);
  }
}

.book-details__desc {
  position: relative;
  text-align: center;

  p {
    text-align: left;
    @include font(default);
    @include font-size(base);
    color: $color-text;
    position: relative;
    max-height: 6rem;
    overflow: hidden;
    margin: 0 0 $spacing 0;

    @include min-width(md) {
      max-height: 12rem;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 10rem;
      position: absolute;
      bottom: 0;
      transition: opacity 500ms $ease-out-quart;
      background-image: linear-gradient(to bottom, rgba(color(neutral,5),0) 0%, color(neutral,5) 100%);
    }
  }

  a {
    color: $color-text;
  }
}

.book-details__desc-open {
  p {
    max-height: none;
    &:after {
      opacity: 0;
    }
  }
}

.book-details__search-summary {
  @include font(default, normal);
  @include font-size(base);
  margin-top: $spacing--large;
  margin-bottom: 0;

  @include min-width(md) {
    @include font-size(medium);
  }
}

@keyframes bd-image {
  from {
    opacity: 0;
    transform: translateX(-5rem) scale(0.7);
  }
}

@keyframes bd-content {
  from {
    opacity: 0;
    transform: translateX(-2rem);
  }
}

@keyframes bd-opacity {
  from {
    opacity: 0;
  }
}


@keyframes bd-bgimage {
  from {
    opacity: 0;
  }
}
