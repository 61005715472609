@import '~@bokbasen/react-style-core/scss/common/helpers';

.book-prio {
    position: relative;
    text-align: left;
    color: $color-light;
    margin: 0 0 $spacing--large;
    overflow: hidden;
    margin-left: -($spacing);
    margin-right: -($spacing);

    @include min-width(sm) {
        margin-left: -($spacing);
        margin-right: -($spacing);
    }

    @include min-width(lg) {
        margin: 0 0 $spacing--xlarge;
        border-radius: $border-radius;
    }
}

.book-prio__image {
    display: block;
    position: relative;
    width: 100%;
    height: 18rem;
    object-fit: cover;
    filter: blur(7px);
    z-index: -1;
    pointer-events: none;

    @include min-width(md) {
        height: 18rem;
    }

    @include min-width(lg) {
        height: 22rem;
    }
}

.book-prio__content {
    position: absolute;
    display: flex;
    align-items: flex-end;
    flex-basis: 100%;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(0, 0, 0, 0.6);
}
