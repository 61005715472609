@import '~@bokbasen/react-style-core/scss/common/helpers';

.section-toggle {

}

.section-toggle__trigger {
    @include font(default, bold);
    @include font-size(base);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $spacing 0;
    border-bottom: 1px solid color(neutral, 10);
    width: 100%;

    @include min-width(md) {
        @include font-size(medium);
    }
}

/* Disable focus outline on mouse and touch interaction without loosing the accessibility feature of focus */
.section-toggle__trigger:active, .section-toggle__trigger--disable-focus-styling {
    outline: none;
}

.section-toggle__caption svg {
    fill: $color-text;
}

.section-toggle__content {
    padding: 0;
    overflow: auto;
    display: none;
    padding-bottom: $spacing--large;
}

.section-toggle--open .section-toggle__trigger {
    color: $color-links;
    border-bottom: none;
}

.section-toggle--open .section-toggle__caption svg {
    fill: $color-links;
}

.section-toggle--open .section-toggle__content {
    display: block;
    animation: section-toggle-content-in 200ms $ease-out-quart forwards;
}

@keyframes section-toggle-content-in {
    from {
        opacity: 0;
        transform: translateY(-1rem);
    }
}

.section-toggle--blead.section-toggle--open .section-toggle__trigger {
    position: relative;
    background-color: $color-light;

    &:before {
        content: '';
        position: absolute;
        bottom: -2rem;
        width: 100%;
        z-index: $level-1;
        left: 0;
        height: 2rem;
        pointer-events: none;
    }
}

.section-toggle--blead {
    .section-toggle__trigger {
        padding-left: $spacing--medium;
        padding-right: $spacing--xsmall;
    }

    .section-toggle__content {
        border-bottom: 1px solid color(neutral, 10);
    }
}

.section-toggle--small {
    .section-toggle__trigger {
        display: inline-flex;
        padding: $spacing--xsmall  $spacing  ($spacing--xsmall + 0.05rem);
        border-radius: 35px;
        height: 1.95rem;
        @include font(default, normal);
        @include font-size(small);
        vertical-align: middle;
        margin-bottom: $spacing--small;
        line-height: 1;
        width: auto;
        background: transparent;
        border: 1px solid color(neutral,20);
        background: transparent;
        cursor: pointer;
        color: $color-text;

        &:hover,
        &:focus {
            background: color(neutral,20);
            border: 1px solid color(neutral,20);
        }
    }
    .section-toggle__caption {
        order: 1;
    }
    .section-toggle__caption + svg {
        order: 2;
        margin-left: $spacing--xxsmall;
    }
    .section-toggle__content {
        padding-bottom: 0;
        margin-bottom: $spacing;
    }
}

.section-toggle--suffix-mode.section-toggle--open .section-toggle__trigger {
    margin-bottom: $spacing--large;
}

body[data-viewer-theme="sepia"] .section-toggle--appearance-styling {
    &.section-toggle--small .section-toggle__trigger {
        &:hover,
        &:focus {
            background: darken($color-sepia,5%);
        }
    }
    &.section-toggle--blead.section-toggle--open .section-toggle__trigger {
        background: $color-sepia;
        &:before {
            background: linear-gradient(rgba($color-sepia, 1), rgba($color-sepia, 0));
        }
    }
}

body[data-viewer-theme="night"] .section-toggle--appearance-styling {

    .section-toggle__trigger {
        color: $color-light;
        border-color: color(neutral,80);
        svg {
            fill: $color-light;
        }
        &:hover,
        &:focus {
            background: color(neutral,base);
            border-color: color(neutral,base);
        }
    }

    &.section-toggle--open .section-toggle__trigger {
        color: color(secondary);
    }

    &.section-toggle--open .section-toggle__caption svg {
        fill: color(secondary);
    }

    &.section-toggle--blead .section-toggle__trigger {
        &:hover,
        &:focus {
            background: $color-night;
            border-color: color(neutral,80);
        }
    }

    &.section-toggle--blead.section-toggle--open .section-toggle__trigger {
        background: $color-night;
        &:before {
            background: linear-gradient(rgba($color-night, 1), rgba($color-night, 0));
        }
    }

    &.section-toggle--blead .section-toggle__content {
        border-color: color(neutral,80);
    }
}
