@import '~@bokbasen/react-style-core/scss/common/helpers';

.menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.menu__item {
    margin-right: $spacing*1.5;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    white-space: nowrap;
    @include font-size(medium);

    &:last-child {
        margin-right: 0;
    }
}

.menu__spacer {
    flex: 1;
}

.menu--wrap {
    flex-wrap: wrap;
    .menu__item {
        margin-bottom: $spacing--medium;
    }
}
.menu--small.menu--wrap .menu__item {
    margin-bottom: 0;
}

.menu--right {
    justify-content: flex-end;
}

.menu__spacer {
    flex: 1;
}

.menu--column {
    flex-direction: column;

    & .menu__item {
        margin-right: 0;
        margin-bottom: $spacing;

        @include font(default);
        @include font-size(medium);
    }

}

.menu--small .menu__item {
    margin-right: 0;

    &:last-child {
        margin-right: 0;
    }
}

.menu--medium .menu__item {
    margin-right: $spacing--medium;

    &:last-child {
        margin-right: 0;
    }
}

.menu--justify-start {
    justify-content: flex-start;
}

.menu--justify-center {
    justify-content: center;
}

.menu--justify-end {
    justify-content: flex-end;
}