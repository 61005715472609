@import '~@bokbasen/react-style-core/scss/common/helpers';

.link-icon {
    display: flex;
    align-items: center;
    color: color(primary);
    position: relative;

    svg {
        display: inline-block;
        margin-right: $spacing--small;
    }

    &:hover {
        & .link-icon__label {
            color: $color-links--hover;
            border-bottom-color: $color-links--hover;
        }
    }

}

.link-icon__label {
    color: $color-links;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: color 300ms $ease-out-quart, border-bottom-color 300ms $ease-out-quart;
}

.link-icon__count {
    position: absolute;
    top: -0.4rem;
    left: 1rem;
    @include font(default, bold);
    @include font-size(small);
    height: 22px;
    padding-left: $spacing--xsmall;
    padding-right: $spacing--xsmall;
    min-width: 22px;
    line-height: 22px;
    border-radius: 20px;
    display: block;
    background-color: color(negative);
    color: $color-light;
    text-align: center;
    opacity: 0;
    animation: bounce-in 400ms forwards;
}

@keyframes bounce-in {
    0%{
        opacity: 0;
        transform: scale(0.3) translate3d(0,0,0);
    }
    50%{
        opacity: 0.9;
        transform: scale(1.1);
    }
    80%{
        opacity: 1;
        transform: scale(0.89);
    }
    100%{
        opacity: 1;
        transform: scale(1) translate3d(0,0,0);
    }
}
