@import '~@bokbasen/react-style-core/scss/common/helpers';

.content-button {
    display: block;
    position: relative;
    width: 100%;
    color: $color-text;
    background: transparent;
    border: $border-width solid color(neutral, 10);
    background-color: color(neutral, 10);
    transition: all 300ms $ease-out-quart, color 300ms $ease-out-quart;
    padding: $spacing--large  $spacing--xlarge  $spacing--large  $spacing;
    border-radius: $border-radius;
    text-align: left;
    //box-shadow: 0 0.1rem 2rem rgba(0, 0, 0, 0.25);

    &:hover,
    &:focus {
        border-color: color(neutral, 20);
        background-color: color(neutral, 20);
    }

    @include min-width(lg) {
        padding: $spacing--large  $spacing--xlarge  $spacing--large  $spacing--large;
    }
}

.content-button__title {
    margin-bottom: $spacing--small;
    color: color(primary);
}

.content-button__arrow-icon {
    position: absolute;
    right: $spacing;
    top: 50%;
    transform: translateY(-50%);
}

.content-button p {
    margin: 0;
    @include font(default, normal);
    @include font-size(base);

    @include min-width(lg) {
        @include font-size(medium);
    }
}

.content-button__image {
    @include min-width(xs) {
        position: absolute;
        top: $spacing;
        left: $spacing;
        bottom: $spacing;
        width: 16.66%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img, svg {
        width: 2rem;
        height: auto;
        margin-bottom: $spacing;
        @include min-width(xs) {
            width: 70%;
            margin-bottom: none;
        }
        @include min-width(sm) {
            width: 50%;
            margin-bottom: none;
        }
        @include min-width(md) {
            width: 40%;
        }
    }
}

.content-button--image {
    @include min-width(xs) {
        padding-left: calc(16.66% + 32px);
    }
}