@import '~@bokbasen/react-style-core/scss/common/helpers';

.reader-styles :global {
    .container-view__empty-message {
        display: none;
    }

    .container-view:empty + .container-view__empty-message {
        display: block;
        padding: $spacing  $spacing--medium;
    }

}