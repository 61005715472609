@import '~@bokbasen/react-style-core/scss/common/helpers';

.reader-styles :global {

    .search-icon-placeholder {
        padding-right: $spacing--small;
        pointer-events: none;
    }

    .search-wrapper {
        padding: $spacing;
    }

    .collection {
        padding: 0 $spacing;
        overflow: auto;
        -webkit-overflow-scrolling: touch; // Smooth scrolling on mobile
        max-height: calc(100vh - 154px); // Search head height
    }

    .collection__empty-message {
        display: none;
        padding: 0 $spacing;
    }

    .collection:empty + .collection__empty-message {
        display: block;
    }

    .collection-item {
        display: block;
        width: 100%;
        padding: $spacing--medium 0;
        font-weight: bold;
        mark {
            background-color: color(secondary);
        }
    }

    a.collection-item {
        border-bottom: none;
        color: $color-text;
        cursor: pointer;
        font-weight: normal;
        transition: background-color 300ms $ease-out-quart;
        &:hover, &:focus {
            border-radius: $border-radius--small;
            background: color(neutral,5);
        }
    }

    .pagination {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        flex-wrap: wrap;
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: $spacing--large;
        margin-top: $spacing;
        a {
            display: flex;
            align-items: center;
            border: none;
            padding: $spacing--small $spacing--medium;
            border-radius: $border-radius--small;
            line-height: 1;
            min-height: 36px;
            &:hover, &:focus {
                background: color(neutral,5);
            }
        }
        .active a {
            background: color(neutral,5);
            color: color(neutral,70);
        }
        .disabled a {
            display: block;
            opacity: 0.5;
            border: none;
        }
        .reader-icon {
            fill: color(primary);
        }
    }
}

body[data-viewer-theme="sepia"] .reader-styles :global {
    a.collection-item {
        &:hover, &:focus {
            background: darken($color-sepia,5%);
        }
    }
    .pagination a {
        &:hover, &:focus {
            background: darken($color-sepia,5%);
        }
    }
}

body[data-viewer-theme="night"] .reader-styles :global {
    .collection-item {
        color: $color-light;
    }
    a.collection-item {
        &:hover, &:focus {
            background: color(neutral,base);
        }
    }
    mark {
        color: $color-text;
    }
    .pagination a {
        color: $color-light;
        &:hover, &:focus {
            background: color(neutral,base);
        }
    }
}