@import '~@bokbasen/react-style-core/scss/common/helpers';

.feature-summary {
    position: relative;
    color: $color-text;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: color(primary);

    @include min-width(sm) {
        justify-content: flex-start;
        flex-direction: row;
    }

    @include min-width(lg) {
        justify-content: center;
        flex-direction: column;
    }
}

.feature-summary--dark {
    background: color(primary);
}

.feature-summary--light {
    background: $color-light;
}

.feature-summary__title {
    color: color(secondary);
    order: 2;
    text-align: center;
    @include font-size(base);
    margin: 0;
    display: none;

    @include min-width(lg) {
        @include font-size(large);
    }
}

.feature-summary__description {
    @include font-size(base);
    margin: 0 0 $spacing;
    color: $color-light;
    order: 3;
    text-align: center;

    @include min-width(sm) {
        text-align: left;
    }

    @include min-width(md) {
        margin: 0;
        text-align: left;
    }

    @include min-width(md) {
        @include font-size(medium);
        width: 80%;
    }

    @include min-width(lg) {
        margin: 0 0 $spacing;
        @include font-size(medium);
        text-align: center;
    }
}

.feature-summary__image-container {
    position: relative;
    padding: 0 $spacing 0 0;
    width: 16rem;
    order: 1;

    @include min-width(sm) {
        padding: $spacing--large $spacing--large 0 0;
    }

    @include min-width(lg) {
        padding: 0;
        height: 18rem;
        width: 18rem;
        order: 1;
    }

    @include min-width(xl) {
        padding: 0;
        height: 22rem;
        width: 22rem;
        order: 1;
    }
}

.feature-summary__image {
    height: 100%;
    width: 100%;
}

.feature-summary__image-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include min-width(sm) {
        height: 80%;
        width: 80%;
        top: 57%;
        left: 44%;
    }

    @include min-width(lg) {
        height: 90%;
        width: 90%;
        top: 50%;
        left: 50%;
    }
}
