@import '~@bokbasen/react-style-core/scss/common/helpers';

.input {
  @include font(default, normal);
  background: $color-light;
  color: $color-text;
  border-radius: $border-radius;
  border: $border-width solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing;
  width: 100%;
  box-sizing: border-box;
  transition: border 300ms $ease-out-expo;
  border: $border-width solid color(neutral, 10);
  //max-width: 35rem;

  &:focus {
    outline: none;
    border-color: $color-focus;
  }
}

.input--no-focus {
    border: none;
}

.input--large {
    padding: $spacing;
    @include font(default, normal);

    @include min-width(md) {
        @include font-size(medium);
    }
}


.input--small {
    padding: $spacing--small $spacing;
}

.input--subtle {
    border: $border-width solid color(neutral, 5);
    background: color(neutral, 5);

    &:focus {
      outline: none;
      border: 1px solid $color-focus;
    }
}
