@import '~@bokbasen/react-style-core/scss/common/helpers';

.reader-styles :global {
    .security {
        position: fixed;
        top: 1rem;
        left: 1rem;
        height: 1.25rem;
        width: 1.25rem;
    }
}
