@import '~@bokbasen/react-style-core/scss/common/helpers';

.header {

}

.header__box {
  background: $color-light;
  padding: $spacing--small 0;
  box-shadow: 0 12px 40px rgba(22, 36, 58, 0.1);
  z-index: 3;
  position: relative;
}

.header__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 4rem;
}

.header__title {
  display: block;
  margin: 0;
  padding: 0;
}

.header__link-button{
  color: $color-links;
  text-decoration: none;
  white-space: nowrap;
  padding-bottom: $spacing--xxsmall;
  transition: color 300ms $ease-out-quart;
  margin-left: $spacing--small;
  margin-right: $spacing--small;
  background-color: transparent;
  cursor: pointer;
  display: inline;
}

.header__link-button--desktop{
  &:hover, &:focus {
    border: none;
    color: color(primary, light);
  }
}

.header__link-button--mobile{
  border-bottom: $border-width--large solid rgba($color-links--hover, 0.3);
  &:hover, &:focus {
    color: $color-links--hover;
    border-bottom-color: $color-links--hover;
  }
}

.header__location {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  //min-width: 280px;
  margin-right: $spacing--large;

  @include min-width(sm) {
    margin-right: $spacing--large;
  }
}

.header__togglemenu {
  @include min-width(lg) {
    display: none;
  }
}

.header__menu {
  display: none;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  @include min-width(lg) {
    display: flex;
  }
}

.header__menu-mobile {
  display: flex;

  @include min-width(lg) {
    display: none;
  }
}

.header__menu-desktop {
  display: none;

  @include min-width(lg) {
    display: flex;
  }
}

.header__menu--show {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 4.5rem;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-light;
  padding: $spacing;
  animation: slide-down 500ms $ease-out-quart both;
}

.header__search-trigger {
  padding: $spacing  $spacing--xlarge  $spacing  $spacing--medium;
  color: $color-text;
  cursor: text;
  border-radius: $border-radius;
  transition: background-color 300ms $ease-out-expo;
  text-align: left;
  margin-right: $spacing;
  display: flex;
  align-items: center;
  border: 1px solid $color-background;
  background: $color-background;
  position: relative;

  svg {
    position: absolute;
    right: $spacing;
  }

  &:hover, &:focus {
    background: $color-background;
  }
}

.header__search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header__searchfield {
  width: 100%;
  position: relative;
  max-width: none;
  margin-left: $spacing--small;

  @include min-width(md) {
    max-width: 380px;
  }

  svg {
    //fill: color(neutral, base);
  }
}

.header__searchfield-options {
  position: absolute;
  right: $spacing--large + $spacing;
  top: 50%;
  transform: translateY(-50%);
}

.header__searchfield-suggestion {
  width: 100%;
  background: $color-light;
  box-shadow: $shadow;
  padding: $spacing;
  padding-bottom: 0;
  border-radius: $border-radius--small;
  position: absolute;
  top: 3.5rem;
  display: none; // TODO: Build something nice out of this
}

.header--show-menu,
.header--search-mode {
  min-height: 5rem;
}

.header--show-menu .header__box {
  background: $color-light;
  box-shadow: none;
}

.header--show-menu .menu__item {
  @include font(default, normal);
  @include font-size(medium);
}

.header--show-menu .header__box,
.header--search-mode .header__box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translate(0, -20%);
  }
}
