@import '~@bokbasen/react-style-core/scss/common/helpers';

.comment {
    position: relative;
    margin-top: -$spacing;
    border: 1px solid transparent;
    border-radius: $border-radius;
    max-width: 25rem;
    min-width: 16rem;
    transition: background-color 150ms $ease-in-quart, border-color 150ms $ease-in-quart;
}

.comment--active {
    background: $color-light;
}

.comment--preview {
    margin-bottom: $spacing;
}

.comment:hover:not(.comment--active):not(.comment--preview) {
    background: $color-neutral-5-transparent;
    border-color: $color-neutral-5-transparent;
}

.comment--active {
    background: $color-neutral-5-transparent;
    & .comment__options {
        display: block;
    }
    & .comment__dot {
        opacity: 0;
    }
}

.comment__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $level-1;
}

.comment__dot {
    display: inline-block;
    position: absolute;
    top: $spacing + $spacing--xsmall + .05rem;
    left: calc(-1 * #{$spacing--medium} / 2);
    width: $spacing--medium;
    height: $spacing--medium;
    border: 2px solid $color-light;
    border-radius: 50%;
    opacity: 1;
    transition: opacity 150ms $ease-in-quart;
}

.comment__preview, .comment__content, .comment__textarea  {
    @include font(default);
    @include font-size(base);
    color: $color-text;
    padding: $spacing;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

.comment__content {
    padding-bottom: 0;
}

.comment__preview {
    cursor: pointer;
    padding-bottom: 0;
}

.comment__preview:hover .comment__hover-actions, .comment__preview:focus .comment__hover-actions {
    opacity: 1;
}

.comment__textarea {
    width: 100%;
    border: none;
    display: block;
    margin: 0;
    margin-bottom: 3px;
    overflow: auto;
    max-height: 20rem;
    &:focus {
        outline: none;
    }
}

.comment__text-wrapper {
    overflow: auto;
    max-height: 20rem;
    position: relative;
}

.comment__text {
    max-height: 6rem;
    overflow: hidden;
}

.comment__text-placeholder {
    color: color(neutral, 70);
    font-style: italic;
}

.comment__preview .comment__text-wrapper {
    overflow: hidden;
    padding-bottom: $spacing;
    &:after {
        content: '';
        position: absolute;
        top: 6rem;
        height: $spacing--small;
        width: 100%;
        display: block;
        border-bottom: 1px solid color(neutral,20);
    }
}

.comment__time {
    @include font-size(xsmall);
    display: block;
    color: color(neutral, 50);
    margin-top: $spacing--xsmall;
}

.comment__hover-actions {
    opacity: 0;
    position: absolute;
    top: $spacing--medium;
    right: $spacing--medium;
    transition: opacity 150ms $ease-in-quart;
    background: color(neutral,5);
    border-radius: 50%;
}

.comment__options {
    display: none;
    padding: $spacing--small $spacing--small $spacing--small $spacing--small + 0.1rem;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    animation: fade-in 150ms $ease-in-quart both;
    min-height: 3rem;
}

.comment__options--color, .comment__options--comment {
    padding: 0 $spacing--small $spacing--medium $spacing--medium + 0.05rem;
    animation: slide-in 150ms $ease-out-quart both;
}

.comment__options--color {
    padding-top: $spacing--small;
    padding-bottom: $spacing--small;
}

.comment--edit-comment {
    padding: 0;
    background: $color-light;
    border-color: $color-links;
}

body[data-viewer-theme="sepia"] .comment--appearance-styling {
    &:hover:not(.comment--active) {
        background: darken($color-sepia, 5%);
    }
    &.comment--active {
        background: darken($color-sepia, 5%);
    }
    .comment__textarea {
        background: darken($color-sepia, 5%);
    }
}

body[data-viewer-theme="night"] .comment--appearance-styling {
    color: $color-light;
    &:hover:not(.comment--active) {
        background: color(neutral, base);
        border-color: color(neutral, base);
    }
    &.comment--active {
        background: color(neutral, base);
    }
    &.comment--edit-comment {
        border-color: color(secondary);
    }
    .comment__textarea {
        background: color(neutral, base);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}

@keyframes slide-in {
    from {
        opacity: 0;
        transform: translate(0) rotateX(45deg);
        transform-origin: left;
    }
}
