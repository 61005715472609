@import '~@bokbasen/react-style-core/scss/common/helpers';

.details-list {
    display: flex;
    margin-bottom: $spacing--large;
    flex-flow: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.details-list__label {
    flex-basis: 100%;
    max-width: 100%;
    @include font(default, normal);
    @include font-size(small);
    margin-bottom: $spacing--xsmall;
    color: color(neutral, 70);

    @include min-width(md) {
        @include font-size(base);
    }
}

.details-list__value {
    flex-basis: 52%;
    max-width: 52%;
    @include font(default, normal);
    @include font-size(base);

    @include min-width(md) {
        @include font-size(medium);
    }
    @include max-width(sm) {
        flex-basis: 100%;
        max-width: 100%;
    }
}
.details-list__value--full {
    flex-basis: 100%;
    max-width: 100%;
}


.details-list__note {
    flex-basis: 48%;
    max-width: 48%;
    @include font(default, normal);
    @include font-size(small);

    @include min-width(md) {
        @include font-size(small);
    }

    @include max-width(sm) {
        flex-basis: 100%;
        max-width: 100%;
    }

    svg {
        margin-bottom: $spacing--xsmall;
    }
}

.details-list__note--info {
    color: color(neutral);
}

.details-list__note--warning{
    color: color(negative);
}