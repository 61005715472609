@import '~@bokbasen/react-style-core/scss/common/helpers';

.breadcrumbs {
    display: flex;
    align-items: center;
}

.breadcrumbs__item {
    display: flex;
    align-items: center;
    color: $color-text--light;
}

.breadcrumbs__icon {
    svg path {
        fill: color(neutral,20);
    }
}
