@import "~@bokbasen/react-style-core/scss/common/helpers";

.button {
    @include font-size(base);
    display: inline-block;
    border-radius: $border-radius--small;
    padding: $spacing--medium $spacing;
    border: $border-width solid transparent;
    color: $color-text;
    white-space: nowrap;
    vertical-align: middle;
    background-color: color(neutral,10);
    transition: all 300ms $ease-out-quart, color 300ms $ease-out-quart;
    line-height: 1;
    &:hover,
    &:focus {
        background-color: color(neutral,20);
    }
}

.button--outline {
  color: $color-text;
  background-color: transparent;
  border-color: rgba($color-text, 0.2);

  &:hover,
  &:focus {
      background: none;
      border-color: rgba($color-text, 0.5);
  }
}

.button--outline-white {
  color: $color-light;
  background-color: transparent;
  border-color: rgba($color-light, 0.3);

  &:hover,
  &:focus {
      background: none;
      border-color: rgba($color-light, 0.8);
  }
}

.button--xsmall {
    padding: $spacing--xsmall  $spacing  ($spacing--xsmall + 0.05rem);
    @include font(default, normal);
    @include font-size(small);
    border-radius: 35px;
    height: 1.95rem;
}

.button--small {
    padding: $spacing--small $spacing;
    @include font(default, normal);
    @include font-size(small);
    height: 2.4rem;
}

.button--medium {
    padding: $spacing--small $spacing;
    @include font(default, normal);
    @include font-size(medium);
}

.button--large {
    padding: $spacing--medium $spacing--large;
    border-radius: 100px;
    @include font-size(medium);
    @include font(default, bold);
}

.button--primary {
  color: $color-links-secondary;
  background-color: color(primary);

  &:hover,
  &:focus {
      background-color: color(primary, light);
  }
}

.button--primary-light {
  color: $color-links-secondary;
  background-color: color(primary, light);

  &:hover,
  &:focus {
      background-color: color(primary, xlight);
  }
}

.button--secondary {
  color: $color-links;
  background-color: $color-links-secondary;
  &:hover,
  &:focus {
      color: $color-links;
      background-color: color(secondary, dark);
  }
}

.button--tertiary {
    color: $color-text;
    background-color: transparent;
    border-color: none;

    &:hover,
    &:focus {
        background: none;
        border-color: none;
    }
}

.button--icon-only {
  padding: $spacing--small;
  background-color: transparent;
  &:hover,
  &:focus {
      background-color: transparent;
  }
}

.button--subtle {
  background-color: color(neutral,10);

  &:hover,
  &:focus {
      background-color: color(neutral,20);
  }
}


.button--transparent {
  background-color: transparent;
  color: color(primary);

  &:hover,
  &:focus {
      background-color: transparent;
  }
}

.button--bright {
  color: color(primary);
  background-color: $color-light;

  &:hover,
  &:focus {
      background-color: $color-light;
  }
}

.button--negative {
  color: $color-light;
  background-color: color(negative);
  &:hover,
  &:focus {
      color: $color-light;
      background-color: darken(color(negative),5%);
  }
}

.button--loading {
    animation: pulse 2000ms linear infinite;
}

.button:disabled, .button--disabled {
    background-color: color(neutral, 70);
    color: color(sepia, base);
    pointer-events: none;
}

.button--fullwidth {
    width: 100%;
    text-align: center;
}

body[data-viewer-theme="night"] .button--appearance-styling {
    background-color: color(neutral,base);
    color: $color-light;
    &.button--primary {
        background: color(secondary);
        color: $color-text;
    }
}

@keyframes pulse {
    0% {
        opacity: 0.75;
    }
    50% {
        opacity: 0.25;
    }
    100% {
        opacity: 0.75;
    }
}