@import '~@bokbasen/react-style-core/scss/common/helpers';

.loading {
    display: flex !important; /* To remove flash of unstyled svg during page loading */
    justify-content: center;
    align-items: center;
    width: 100%;
    animation: loading-content-in 1000ms $ease-out-quart both;
}

.loading__mark {
    display: block;
    margin: 0;
    position: relative;
}

.loading__animation {
    position: relative;
    height: 2.7rem;
    margin-bottom: $spacing;

    svg {
        height: 2.7rem!important;
        width: 2.7rem!important;
        position: absolute;
        top: 50%;
        left: 52%;
        max-width: none;
        transform: translate(-50%, -50%);

        path {
            fill: color(primary);
        }
    }
}

.loading--large {
    height: 60vh;
}

@keyframes loading-content-in {
    from {
        opacity: 0;
    }
}