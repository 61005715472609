@import '~@bokbasen/react-style-core/scss/common/helpers';

.reader-styles :global {

    .error {
      z-index: 20;
      background-color: $color-background;
      color: color(negative);
      height: 100%;
      top: 0;
      padding-top: 40vh;
      position: fixed;
      text-align: center;
      width: 100%;
      user-select: none;
      cursor: default;

      .reader-icon {
        display: block;
        width: 3rem;
        height: 3rem;
        fill: color(negative);
        margin: 0 auto;
      }

      span {
        display: block;
        margin-bottom: $spacing;
        @include font-size(medium);
      }

      button {
        border-radius: $border-radius;
        @include font-size(base);
        @include font(default, normal);
        padding: $spacing--small $spacing;
        background-color: color(neutral,10);
        color: $color-text;
        transition: all 300ms $ease-out-quart, color 300ms $ease-out-quart;
        &:hover, &:focus {
            background-color: color(neutral,20);
        }
        + button {
          margin-left: $spacing;
        }
      }
    }
}