@import '~@bokbasen/react-style-core/scss/common/helpers';

.form-group {
  margin-bottom: $spacing*1.5;
}

.form-group__label {
  @include font(default);
  @include font-size(small);
  color: color(neutral, 70);
  display: block;
  margin-bottom: $spacing--small;

  @include min-width(lg) {
      @include font-size(base);
  }
}

.form-group__error {
    color: color(negative);
    margin-top: $spacing--medium;
    animation: form-group-shake-in 700ms $ease-in-quart both;
    svg {
        fill: color(negative);
    }
}

.form-group--error input {
    color: color(negative);
}

@keyframes form-group-shake-in {
    0% {
        opacity: 0;
    }
    10%, 90% {
        transform: translate(-1px, 0);
    }
    20%, 80% {
        transform: translate(2px, 0);
    }
    30%, 50%, 70% {
        transform: translate(-4px, 0);
    }
    40%, 60% {
        transform: translate(4px, 0);
    }
    60% {
        opacity: 1;
    }
    100% {
        transform: translate(0, 0);
    }
};