@import '~@bokbasen/react-style-core/scss/common/helpers';

@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(-2rem);
    }
}

.list {
    margin-bottom: $spacing;
    border: none;
}

.list__item {
    animation: fade-in 150ms $ease-out-quart backwards;
    @include animation-delay(24, 30ms, 60ms);
}

.list--base {
    & .list__item {
        display: block;
        @include font-size(base);
        margin-bottom: $spacing--medium;
    }
}

.list--medium {
    & .list__item {
        display: block;
        @include font-size(medium);
        margin-bottom: $spacing--medium;
    }
}


.list--feature {
    margin-top: $spacing--large;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: $spacing--large;

    @include min-width(lg) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include min-width(xl) {
        grid-template-columns: repeat(3, 1fr);
    }

    & .list__item {
        display: block;
        border: none;
        padding-right: $spacing;
        margin-right: $spacing--large;
        @include font-size(base);
        @include min-width(lg) {
            @include font-size(medium);
        }
    }
}


.list--book-list {
    margin-bottom: $spacing;
    border: none;

    & .list__item {
        padding: $spacing--large 0;
        border-bottom: 1px solid color(neutral, 20);
    }
}


.list--book-list-half {
    margin-top: $spacing--large;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: $spacing--large;
    margin: 0;

    @include min-width(lg) {
        grid-template-columns: repeat(2, 1fr);
    }

    & .list__item {
        display: block;
        border: none;
        margin: 0;
        padding: $spacing--large 0;
        border-bottom: 1px solid color(neutral, 20);
    }
}


.list--book-half {
    margin-top: $spacing--large;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: $spacing--large;
    margin: 0;

    @include min-width(lg) {
        grid-template-columns: repeat(2, 1fr);
    }

    & .list__item {
        display: block;
        border: none;
        margin: 0;
    }
}

.list--publishers {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0;

    @include min-width(lg) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include min-width(xl) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    & .list__item {
        display: block;
        text-align: center;
        border: none;
        vertical-align: middle;
        padding: $spacing 0;
        margin-bottom: 0;
        border-right: 1px solid color(neutral, 10);
        border-bottom: 1px solid color(neutral, 10);


        img {
            height: 40px;
            display: block;
            margin: 0 auto;

            @include min-width(lg) {
                height: 60px;
            }
        }
    }
}

.list--buttons-horizontal {
    margin: 0;

    & .list__item {
        display: inline-block;
        border: none;
        vertical-align: middle;
        margin: 0 $spacing--small $spacing--small 0;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.list--buttons-vertical {

    & .list__item {
        display: block;
        border: none;
        vertical-align: middle;
        margin: 0 $spacing--xsmall $spacing--small;
    }
}

.list--buttons-content {

    & .list__item {
        display: block;
        margin: 0 0 $spacing;
    }
}

.list--large {
    & .list__item {
        @include font-size(large);
        margin-bottom: $spacing--medium;
    }
}

.list--book-cover {
    display: flex;
    flex-basis: 100%;
    max-width: 100%;
    flex-direction: row;
    flex-wrap: wrap;

    & .list__item {
        flex-basis: 50%;
        max-width: 50%;
        padding: 0;
        border: none;
        margin-bottom: $spacing--large;

        @include min-width(sm) {
            flex-basis: 33.33%;
            max-width: 33.33%;
        }
        @include min-width(md) {
            flex-basis: 25%;
            max-width: 25%;
        }
        @include min-width(lg) {
            flex-basis: 20%;
            max-width: 20%;
        }
        @include min-width(xl) {
            flex-basis: 16.66%;
            max-width: 16.66%;
        }
    }
}
