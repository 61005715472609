@import "~@bokbasen/react-style-core/scss/common/helpers";

.confirmation {
}

.confirmation__error-header{
  animation: shake-in 700ms $ease-in-quart both;
  @include animation-delay(12, 30ms, 200ms);
}

.confirmation__infotext{
  animation: fade-in  1000ms $ease-in-quart both;
  @include animation-delay(12, 30ms, 200ms);
  @include font(default, bold);
  @include font-size(large);
  color: $color-light;
  margin: 0 0 $spacing--small;
  text-align: center;
  @include font-size(large);
  @include min-width(sm) {
    text-align: left;
  }
}


@keyframes lp-opacity {
  from {
    opacity: 0;
  }
}

@keyframes shake-in {
  0% {
    opacity: 0;
  }
  10%, 90% {
    transform: translate(-1px, 0);
  }
  20%, 80% {
    transform: translate(2px, 0);
  }
  30%, 50%, 70% {
    transform: translate(-4px, 0);
  }
  40%, 60% {
    transform: translate(4px, 0);
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
  }
};

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }
}