@import '~@bokbasen/react-style-core/scss/common/helpers';

.details {
    display: flex;
    flex-wrap: wrap;
}

.details--half .details__item {
    flex-basis: 50%;
    max-width: 50%;
}

.details__item {
    margin-bottom: $spacing--large;
    padding-right: $spacing--large;
    flex-basis: 100%;
    max-width: 100%;
}

.details__label {
    @include font(default, normal);
    @include font-size(small);
    margin-bottom: $spacing--xsmall;
    color: color(neutral, 70);

    @include min-width(md) {
        @include font-size(base);
    }
}

.details__value {
    @include font(default, normal);
    @include font-size(base);

    @include min-width(md) {
        @include font-size(medium);
    }
}
