@import '~@bokbasen/react-style-core/scss/common/helpers';

.dropdown {
    position: relative;
    display: inline-flex;
    z-index: $level-1;
}

.dropdown__trigger {
    position: relative;
    padding: $spacing--small;
    border: $border-width solid transparent;
    display: inline-flex;
    align-items: center;
    font-weight: inherit;
    white-space: nowrap;
    color: $color-text;
}

.dropdown__arrow svg {
    transition: all 250ms $ease-in-quart both;
}

.dropdown--expanded {
    z-index: $level-2;
}

.dropdown__trigger--expanded {
    .dropdown__arrow svg {
        transform: rotateX(-180deg);
    }
}

/* Disable focus outline on mouse and touch interaction without loosing the accessibility feature of focus */
.dropdown__trigger:active, .dropdown__trigger--disable-focus-styling {
    outline: none;
}

.dropdown__content {
    position: absolute;
    z-index: 3;
    top: $spacing--large + $spacing--small;
    left: 0;
    overflow: hidden;
    background-color: $color-light;
    border-radius: $border-radius--small;
    animation: content-in 300ms $ease-out-expo both;
    transform-origin: 80% top;
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.15);
}

.dropdown__content--small {
    right: 0;
    left: auto;
    width: 200px;
}

.dropdown__option {
    display: block;
    white-space: nowrap;
    width: 100%;
    outline: 0;
    text-align: left;
    padding: $spacing--medium $spacing;
    color: $color-text;
    animation: option-in 300ms $ease-out-expo both;
    @include animation-delay(5, 40ms, 40ms);
    position: relative;
    @include font(default, normal);
    @include font-size(base);

    &:hover,
    &:focus {
        color: $color-light;
        background-color: $color-links;

        &:before {
            background:$color-links;
        }

        svg {
            fill: $color-light;
        }
    }

    &:first-child {
        padding: $spacing--medium $spacing;

        &:before {
            display: none;
        }
    }

    &:last-child {
        padding: $spacing--medium $spacing;
    }

    &:before {
        position: absolute;
        content: "";
        height: 1px;
        width: 100%;
        top: 0;
        background: color(neutral, 10);
        left: 0;
    }
}

.dropdown__option--icon {
    display: flex;
    align-items: center;

    svg {
        height: 18px;
        width: 18px;
        margin-right: $spacing--small;
        fill: $color-text;
    }
}

.dropdown__backdrop {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-backdrop;
    animation: fade-in 300ms ease-out both;
}

.dropdown--expanded {
    z-index: $level-4;
}

.dropdown--animate-out {
    pointer-events: none;

    .dropdown__backdrop {
        animation: fade-out 300ms ease-in forwards;
    }

    .dropdown__trigger:before {
        transform: translateY(-($border-width * 4));
        transition-delay: 360ms;
    }

    .dropdown__arrow svg {
        transform: rotateX(0deg);
    }

    .dropdown__content {
        animation: content-out 200ms $ease-in-quart forwards;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}

@keyframes fade-out {
    to {
        opacity: 0;
    }
}

@keyframes content-in {
    from {
        opacity: 0;
        transform: translateY(-2rem) scale(0.5);
    }
}

@keyframes content-out {
    to {
        opacity: 0;
        transform: translateY(-1rem) scale(0.9);
    }
}

@keyframes option-in {
    from {
        opacity: 0;
        transform: translateY(-1rem);
    }
}
