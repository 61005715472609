@import '~@bokbasen/react-style-core/scss/common/helpers';

.progress-bar {
    display: flex;
    width: calc(100% - 40px);
    position: relative;
    flex-direction: column;
    background: rgba($color-light, 0.2);
    height: 8px;
    margin: $spacing--medium 0 $spacing 0;
    border-radius: 100px;
}

.progress-bar__progress {
    width: 0;
    display: block;
    height: 8px;
    background: color(secondary);
    border-radius: 100px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    animation: progressWidth 1000ms ease forwards;
}

.progress-bar__percentage {
    position: absolute;
    display: block;
    @include font(default, bold);
    @include font-size(xsmall);
    color: $color-light;
    text-align: center;
    right: -40px;
    top: -2px;
    width: 40px;
    opacity: 0;
    animation: progressWidthIn 1000ms ease forwards;
    animation-delay: 250ms;
}

.progress-bar--small {
    position: absolute;
    width: 100%;
    background: transparent;
    height: 8px;
    border-radius: 0;
    top: auto;
    margin-top: 0px;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 3rem;
        background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
        pointer-events: none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    & .progress-bar__progress {
        border-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        z-index: 1;
    }
}

@keyframes progressWidth {
  from {
      width: 0%;
    }
}

@keyframes progressWidthIn {
  to {
      opacity: 1;
    }
}
