@import '~@bokbasen/react-style-core/scss/common/helpers';

.reader-styles :global {
    .next-chapter, .previous-chapter {
        left: 50%;
        transform: translate(-50%,0);
        position: absolute;
    }

    .previous-chapter {
        top: $spacing;
        animation: chapter-navigation-previous 150ms $ease-out-expo both;
    }

    .next-chapter {
        bottom: $spacing--large;
        animation: chapter-navigation-next 150ms $ease-out-expo both;
    }
}

@keyframes :global(chapter-navigation-next) {
    from {
        opacity: 0;
        transform: translate(-50%,50%)  scale(0.75) ;
    }
}

@keyframes :global(chapter-navigation-previous) {
    from {
        opacity: 0;
        transform: translate(-50%,-50%)  scale(0.75) ;
    }
}