@import '~@bokbasen/react-style-core/scss/common/helpers';

.button-group {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: $spacing--small 0;
    margin-bottom: $spacing;

    > * {
        border-radius: 0;
        margin-left: -1px;
    }

    > *:first-child {
        border-top-left-radius: $border-radius--small;
        border-bottom-left-radius: $border-radius--small;
        margin-left: 0;
    }

    > *:last-child {
        border-top-right-radius: $border-radius--small;
        border-bottom-right-radius: $border-radius--small;
        margin-left: -1px;
    }
}

.button-group--rounded {
    > * {
        border-radius: 0;
    }

    > *:first-child {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
    }

    > *:last-child {
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
    }
}

.button-group--justify {
    > * {
        flex-grow: 1;
    }
}