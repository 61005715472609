@import '~@bokbasen/react-style-core/scss/common/helpers';

.container {
    display: block;
    margin: 0 auto;
    width: 100%;
    padding: 0 $spacing;
    @include min-width(md) {
        padding: 0 $spacing;
    }
}

.container--medium {
  max-width: $container-width;
}

.container--small {
  max-width: $container-width--small;
}

.container--xsmall {
  max-width: $container-width--xsmall;
}

.container--large {
  max-width: $container-width--large;
}

.container--xlarge {
  max-width: $container-width--xxlarge;
}

.container--full {
  max-width: none;
}

.container--left {
    margin-left: 0;
}

.container--right {
    margin-right: 0;
}