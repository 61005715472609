@import '~@bokbasen/react-style-core/scss/common/helpers';

.expandable-text {
  position: relative;
  text-align: center;

  p {
    text-align: left;
    @include font(default);
    @include font-size(base);
    color: $color-text;
    position: relative;
    max-height: 6rem;
    overflow: hidden;
    margin: 0 0 $spacing 0;

    @include min-width(md) {
      max-height: 12rem;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 10rem;
      position: absolute;
      bottom: 0;
      transition: opacity 500ms $ease-out-quart;
      background-image: linear-gradient(to bottom, rgba(color(neutral,5),0) 0%, color(neutral,5) 100%);
    }
  }

  a {
    color: $color-text;
  }
}

.expandable-text-open {
  p {
    max-height: none;
    &:after {
      opacity: 0;
    }
  }
}
