@import '~@bokbasen/react-style-core/scss/common/helpers';

.keyboard-focus-jump {
    position: absolute;
    display: flex;
    align-items: center;
    background: $color-light;
    color: $color-links;
    padding: $spacing--small $spacing--medium;
    min-height: 40px;
    min-width: 40px;
    border-radius: 20px;
    z-index: $level-4;
    box-shadow: $shadow;
    top: $spacing;
    left: $spacing;
    border: 1px solid color(primary);
    opacity: 0;
    transition: all 200ms $ease-out-quart;
    transform: translate(0,-100%);
}
.keyboard-focus-jump:focus {
    outline: none;
    opacity: 1;
    transform: translate(0,0);
}

.keyboard-focus-jump__target:focus {
    outline: none;
}