@import '~@bokbasen/react-style-core/scss/common/helpers';

.tag {
    @include font(default, default);
    @include font-size(small);
    display: inline-block;
    padding: $spacing--xsmall  $spacing  ($spacing--xsmall + 0.05rem);
    height: 1.95rem;
    border-radius: 36px;
    line-height: 1;
    background: $color-links;
    border: 1px solid $color-links;
    color: $color-links-secondary;
    vertical-align: middle;
    margin-left: $spacing--small;
    margin-right: $spacing--small;
    animation: bounce-in 300ms forwards;
    opacity: 0;


    &:hover,
    &:focus {
        color: $color-links-secondary;
        border: 1px solid $color-links--hover;
        background-color: $color-links--hover;

        .tag__icon svg { fill: color(secondary); }
    }
}

.tag__content, .tag__icon {
    display: inline-block;
    vertical-align: middle;
}
.tag__icon {
    margin-left: $spacing--small;
    margin-right: -$spacing--xsmall;
    svg { fill: color(secondary); }
}

.tag--left {
    margin-right: 0;
    padding-right: $spacing--small;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid $color-light;
}

.tag--right {
    margin-left: 0;
    padding-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

@keyframes bounce-in {
    0%{
        opacity: 0;
        transform: scale(0.7) translate3d(0,0,0);
    }
    50%{
        opacity: 0.9;
        transform: scale(1.05);
    }
    80%{
        opacity: 1;
        transform: scale(0.95);
    }
    100%{
        opacity: 1;
        transform: scale(1) translate3d(0,0,0);
    }
}
