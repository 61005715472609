@import '~@bokbasen/react-style-core/scss/common/helpers';

.footer {
    position: relative;
    display: flex;
    text-align: left;
    color: $color-text;
    flex-direction: column;
    background: color(neutral, 10);

    @include min-width(lg) {
        justify-content: center;
    }
}

.footer--large {
    background: color(secondary);
}

.footer--small {
    background: $color-light;
}

.footer__publishers {
    padding: 0;
    text-align: center;
    display: flex;
    background: color(neutral, 20);
    flex-direction: row;

    @include min-width(lg) {
        padding: 0;
        flex-direction: column;
    }
}

.footer__content {
    margin: 0 auto;
    display: grid;
    width: 100%;
    padding: $spacing--xlarge $spacing;
    color: $color-light;
    grid-template-columns: 1fr;

    @include min-width(md) {
        padding: $spacing--xlarge $spacing;
        grid-template-columns: 2fr 2fr;
    }

    @include min-width(lg) {
        padding: $spacing--xlarge  $spacing--large;
    }

    @include min-width(xl) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        padding: $spacing--xlarge  $spacing--large;
    }
}

.footer__content div {
    padding-right: $spacing;
    margin: 0 0 $spacing--large;

    @include min-width(xl) {
        padding-right: $spacing--large;
    }

    &:last-child {
        margin-bottom: 0;
    }
}


.footer__logo {
    position: relative;
    margin: 0 0 $spacing--small;
    color: color(primary);
    @include font(default, bold);
    @include font-size(base);
    display:inline-flex;
    vertical-align:top;
    justify-content:center;
    align-items:center;

    @include min-width(xs) {
        @include font-size(medium);
    }
    svg {
      fill: color(primary);
      width: 3rem;
      max-width: none;

      @include min-width(lg) {
         width: 8rem;
         margin: 0 0 $spacing--large;
      }
    }


}
.footer__title {
    margin: 0 0 $spacing--small;
    color: color(primary);
    @include font(default, bold);
    @include font-size(base);

    @include min-width(xs) {
        @include font-size(medium);
    }
}

.footer__description, .footer__cta {
    color: $color-text;
    @include font-size(base);
    margin: 0;

    @include min-width(lg) {
        @include font-size(medium);
    }
    svg {
        margin-left: 0.5em;
        display: inline;
        fill: none;
        stroke: $color-links;
        stroke-width: 3;
        vertical-align: middle
    }
}

.footer__cta {
    margin-top: $spacing;
   
}
