@import '~@bokbasen/react-style-core/scss/common/helpers';

// prmo (and not promo) is because of ad-blocker
.prmo {
    position: relative;
    text-align: left;
    height: 100%;
    display: flex;
    color: $color-light;
    background: color(primary);
    z-index: 1;
}

.prmo--large {
    min-height: 500px;
    height: auto;

    @include min-width(lg) {
        min-height: 700px;
        height: 100%;
    }

    & .prmo__content {
        padding: $spacing--xxlarge  0;

        @include min-width(md) {
            padding: $spacing--xxxlarge  $spacing--large;
        }
    }
}

.prmo__title {
    color: inherit;
    margin: 0 0 $spacing--medium;
    @include font(default, black);
    @include font-size(xlarge);

    @include min-width(md) {
        @include font(default, black);
        @include font-size(xlarge);
        margin-bottom: $spacing;
    }
}

.prmo__description {
    margin: 0 0 $spacing;
    color: $color-light;

    @include min-width(md) {
        @include font-size(medium);
    }
}

.prmo__content--color {
    background-color: #e5eaef;
    color: #38007a;
}

.prmo__title,
.prmo__description {
    //text-shadow: 0 0 180px black;
}

.prmo__image {
    display: block;
    width: 100%;
    min-height: 500px;
    height: 100%;
    max-height: 800px;
    object-fit: cover;
    z-index: 1;
    position: relative;
}


.prmo__image-radial {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.prmo__image-radial:before {
    position: absolute;
    content:"";
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    background: radial-gradient(rgba(black, 0.5) 0%, rgba(black, 0.5) 80%), rgba(black, 0);

    @include min-width(md) {
        background: radial-gradient(rgba(black, 0.4) 0%, rgba(black, 0.4) 80%), rgba(black, 0);
    }
}

.prmo__content {
    position: relative;
    display: flex;
    z-index: 3;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: $spacing--xlarge  0;

    @include min-width(md) {
        padding: $spacing--xxxlarge  $spacing--large;
    }
}
