@import '~@bokbasen/react-style-core/scss/common/helpers';

.checkbox {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    margin-right: $spacing;
    margin-bottom: $spacing--medium;
    position: relative;
}

.checkbox__label {
    @include font(default);
    @include font-size(base);
}

.checkbox__suffix {
    display: inline-block;
    margin-left: 0.25em;
    color: $color-text--light;
    @include font-size(small);
}

.checkbox__indicator {
    $indicator-size: 1.5rem;
    display: inline-flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: $indicator-size;
    height: $indicator-size;
    border-radius: $border-radius--small;
    flex-shrink: 0;
    margin-right: $indicator-size/3;
    border: $border-width solid color(neutral, 10);
    background-color: $color-light;

    &:before {
        content: '';
        width: 0.6rem;
        height: 0.4rem;
        border-left: 2px solid transparent;
        border-bottom: 2px solid transparent;
        transform: translate(0,-25%) rotate(-45deg);
    }
}

.checkbox__checkbox {
    position: absolute;
    opacity: 0;
    width: 1rem; // Size defined to give VoiceOver a correct selection area
    height: 1rem; // Size defined to give VoiceOver a correct selection area

    &:checked {
        & ~ .checkbox__indicator {
            background: $color-links;
            border-color: $color-links;

            &:before {
                background: $color-links;
                border-left-color: color(secondary);
                border-bottom-color: color(secondary);
            }
        }
    }

    &:focus {
        & ~ .checkbox__indicator {
            border-color: $color-links;
        }
    }

    &:checked:focus {
        & ~ .checkbox__indicator {
            border-color: $color-light;
            box-shadow: 0 0 0 1px $color-links;
        }
    }
}

.checkbox--checked {

}

.checkbox--inline {
    display: inline-flex;
}

.checkbox--disabled {
    opacity: 0.5;
    cursor: auto;
    pointer-events: none;
}

.checkbox--primary {
    background: color(neutral,10);
    padding: $spacing--small;
    border-radius: $border-radius--small;
    & .checkbox--checked {
        background-color: color(secondary);
    }
}