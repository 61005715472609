@import '~@bokbasen/react-style-core/scss/common/helpers';

.highlight {
  background-color: color(secondary);
  position: relative;
  line-height: 1;
  color: inherit;
  display: inline-block;
  border: 0 solid transparent;
  border-radius: 120px 10px / 10px 90px;
  border-width: 0.15em 0;
  /* 0.25em is roughly equal to one space character. */
}

.highlight > span {
  position: relative;
  z-index: 1;
  padding: 0 0.25em;
}

.highlight--variant-0 {
    border-radius: 120px 10px / 10px 90px;
}

.highlight--variant-1 {
    border-radius: 10px 90px / 90px 10px;
}

.highlight--variant-2 {
    border-radius: 20px 10px / 10px 30px;
}

.highlight--preview {
    display: block;
    margin-bottom: $spacing;
    @include font-size(base);
    line-height: 1.5 !important;
}

.highlight--underline {
    background-color: transparent;
    border: none;
    & > span {
        border-bottom: 2px solid color(secondary);
    }
}