@import '~@bokbasen/react-style-core/scss/common/helpers';

.marker-demo {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
}

.marker-demo__placeholder {
    position: relative;
    text-align: left;
    font-family: Georgia;
    font-size: 18px;

    color: color(neutral, 30);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

    &:before {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        position: absolute;
        height: 150px;
        background: linear-gradient(to top, rgba($color-background, 0.1) 0%, rgba($color-background, 1) 100%);
    }

    &:after {
        content: "";
        left: 0;
        bottom: 0;
        width: 100%;
        position: absolute;
        height: 150px;
        background: linear-gradient(to bottom, rgba($color-background, 0.1) 0%, rgba($color-background, 1) 100%);
    }
}

.marker-demo__placeholder mark {
    position: relative;
    z-index: 5;
    background: color(secondary);
}

.marker-demo__hidemob {
    display: none;

    @include min-width(md) {
        display: block;
    }
}

.marker-demo__marker {
    position: absolute;
    z-index: 7;
    width: 100%;
    height: 100%;
    left: 50%;
    top: -50%;
}

.marker-demo__comment {
    position: absolute;
    z-index: 6;
    width: 100%;
    left: $container-width--xsmall;
}

@keyframes marker-content-in {
    from {
        opacity: 0;
        transform: translateY(-2rem)  scale(0.5);
    }
}
