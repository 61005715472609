@import '~@bokbasen/react-style-core/scss/common/helpers';

.wizard {
    position: relative;
    overflow-x: hidden;
    min-height: 100vh;
    background: $color-background;
    & > * {
        animation: lp-opacity 500ms $ease-out-quart both;
        @include animation-delay(5, 50ms, 100ms);
    }
}

.wizard__header {
    background: transparent;
    padding: $spacing--small 0;
    z-index: 3;
    position: relative;
}

.wizard__bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 4rem;
    padding: 0 $spacing;
    @include min-width(lg) {
        padding: 0 $spacing;
    }
}

.wizard__step {
    margin: $spacing--xlarge 0 $spacing--xxlarge;
    //animation: slide-in 500ms $ease-out-quart both;
}

.wizard__title {
}

.wizard__preamble {
    @include font(default, base);
    @include font-size(medium);
    color: $color-text;
    margin: 0 0 $spacing--xlarge 0;
}

.wizard__content {
    margin: $spacing--large 0;
}

.wizard__previous {
    margin-bottom: $spacing;
}

@keyframes slide-in {
    from {
        opacity: 0;
        transform: translate(20%, 0%);
    }
}

@keyframes lp-opacity {
    from {
        opacity: 0;
    }
}