@import '~@bokbasen/react-style-core/scss/common/helpers';

.note {
    display: flex;
    align-items: center;
    padding: $spacing  $spacing--medium;
    border: 1px solid color(neutral, 10);
    background: color(neutral, 10);
    color: $color-text;
    border-radius: $border-radius;
    @include font-size(small);
    margin-bottom: $spacing--large;
    //animation: note-slide-in  300ms $ease-in-quart both;
    animation: bounce-in 300ms forwards;
    width: 100%;

    @include min-width(md) {
        @include font-size(base);
    }

    & svg {
        margin-right: $spacing--medium;
        min-width: 20px;
    }

    a {
        display: inline-flex;
    }

    button {
        margin-left: $spacing;
    }

    button + button {
        margin-left: $spacing--small;
    }
}

.note--small {
    @include font-size(small);

    @include min-width(md) {
        @include font-size(small);
    }
}

.note--large {
    @include font-size(base);

    @include min-width(md) {
        @include font-size(medium);
    }
}

.note--information {
    border: 1px solid $color-light;
    background: $color-light;

    //box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px, rgba(0, 0, 0, 0.05) 0px 5px 15px;

    svg {
        fill: color(primary);
    }
}

.note--warning {
    border: 1px solid color(negative);
    background: color(negative);
    color: $color-light;
    animation: note-shake-in 700ms $ease-in-quart both;

    svg {
        fill: $color-light;
    }
}

.note--outdated {
    border: 1px solid color(secondary);
    background: color(secondary);
    color: $color-text;

    svg {
        fill: color(primary);
    }
}

.note--transparent {
    border: none;
    background: transparent;
    padding: 0;
    margin-bottom: $spacing;

    svg {
        fill: color(primary);
    }
}


.note--transparent-inverted {
    border: none;
    background: transparent;
    color: rgba($color-light, 1);
    padding: 0;
    margin-bottom: $spacing;

    svg {
        fill: rgba($color-light, 1);
    }
}

@keyframes note-slide-in {
    from {
        opacity: 0;
        transform: translate(2rem, 0);
    }
}

@keyframes note-shake-in {
    0% {
        opacity: 0;
    }
    10%, 90% {
        transform: translate(-1px, 0);
    }
    20%, 80% {
        transform: translate(2px, 0);
    }
    30%, 50%, 70% {
        transform: translate(-4px, 0);
    }
    40%, 60% {
        transform: translate(4px, 0);
    }
    60% {
        opacity: 1;
    }
    100% {
        transform: translate(0, 0);
    }
};

@keyframes bounce-in {
    0%{
        opacity: 0;
        transform: scale(0.7);
    }
    50%{
        opacity: 0.9;
        transform: scale(1.05);
    }
    80%{
        opacity: 1;
        transform: scale(0.95);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}