@import '~@bokbasen/react-style-core/scss/common/helpers';

.feature-summary-container {
    position: relative;
    text-align: center;
    color: $color-text;
    padding: $spacing--xlarge 0;
    background: color(primary);

    @include min-width(xl) {
        padding: $spacing--xlarge 0;
    }
}

.feature-summary-container--dark {
    background: color(primary);
}

.feature-summary-container--light {
    background: $color-light;
}

.feature-summary-container__image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 500px;
    object-fit: cover;
    z-index: -1;
}

.feature-summary-container__content {
    display: grid;
    grid-template-columns: repeat(1fr);
    max-width: $container-width--xlarge;
    align-items: start;
    margin: 0 auto;
    width: 100%;
    padding: 0 $spacing--large;
    @include min-width(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.feature-summary-container__footer {
    display: flex;
    max-width: $container-width--xlarge;
    align-items: center;
    justify-content: center;
    margin: $spacing--large auto 0;
    width: 100%;
    padding: 0 $spacing;

    svg {
        fill: $color-light;
    }
}
