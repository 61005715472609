@import '~@bokbasen/react-style-core/scss/common/helpers';

.tabs {
    position: relative;
    display: block;
    max-width: $container-width--large;
    margin: 0 auto;
    margin-bottom: $spacing--large;
    padding: 0 $spacing;

    @include min-width(md) {
        margin-bottom: $spacing--xlarge;
    }
}

.tabs__list {
    display: flex;
    flex-direction: row;
    justify-content: left;
    border-bottom: 2px solid color(neutral, 20);

    li:last-child .tabs__tab {
        margin-right: 0;
    }
}

.tabs__list--center {
    justify-content: center;

    li:last-child .tabs__tab {
        margin: 0;
    }
}

.tabs__tab {
    @include font(default);
    @include font-size(base);
    color: $color-text;
    display: block;
    margin: 0 $spacing*1.3 0 0;
    padding: $spacing 0;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    bottom: -2px;

    &:hover,
    &:focus {
        color: $color-links--hover;
        border-bottom-color: transparent;

        svg {
            fill: color(primary, light);
        }
    }

    @include min-width(xs) {
        margin: 0 $spacing*2 0 0;
    }

    @include min-width(lg) {
        @include font(default, bold);
        @include font-size(medium);
        margin: 0 $spacing--large*1.2 0 0;
    }

    svg {
        margin-right: $spacing--small*1.3;
        position: relative;
        top: -2px;
        display: none;
        fill: $color-text;

        @include min-width(md) {
            display: inline-block;
        }
    }
}

.tabs__tab--selected {
    border-bottom-color: $color-links--hover;
    color: color(primary);
    @include font(default, bold);

    &:hover,
    &:focus {
        color: color(primary);
        border-bottom-color: $color-links--hover;

        svg {
            fill: color(primary);
        }
    }

    svg {
        fill: color(primary);
    }
}

.tabs__sort {
    margin-left: auto;
    display: flex;
    margin-right: -5px;
}

.tabs--small {
    margin-bottom: $spacing;
}

.tabs--small .tabs__tab {
    @include font-size(base);
    padding: $spacing--medium 0;
}

body[data-viewer-theme="night"] .tabs--appearance-styling {
    .tabs__tab {
        color: $color-light;
    }
    .tabs__tab--selected {
        color: color(secondary);
        border-bottom-color: color(secondary);
        &:hover,
        &:focus {
            color: color(secondary);
            border-bottom-color: color(secondary);
        }
    }
    .tabs__list {
        border-bottom-color: color(neutral,80);
    }
}
