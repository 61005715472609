@import '~@bokbasen/react-style-core/scss/common/helpers';

.option {
    display: inline-block;
    padding: $spacing--xsmall  $spacing  ($spacing--xsmall + 0.05rem);
    border-radius: 35px;
    height: 1.95rem;
    background: color(neutral,5);
    border: 1px solid color(neutral,5);
    vertical-align: middle;
    position: relative;
    line-height: 1;
    cursor: pointer;
    &:hover {
        background-color: $color-light;
        border: 1px solid $color-light;
    }
}

.option__label {
    @include font(default);
    @include font-size(small);
    pointer-events: none;
    display: inline-block;
}

.option__label-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: $spacing--xsmall;
}

.option__suffix {
    display: inline-block;
    margin-left: 0.25em;
    color: $color-text--light;
    @include font-size(xsmall);
    @include font(default, medium);
}

.option__input {
    position: absolute;
    opacity: 0;
    left: 0;
    width: 100%; // Size defined to give VoiceOver a correct selection area
    height: 1.25rem; // Size defined to give VoiceOver a correct selection area

    &:focus {
        & ~ .option__label {
            outline: 1px solid;
            outline-offset: 2px;
        }
    }
}

/* Disable focus outline on mouse and touch interaction without loosing the accessibility feature of focus */
.option__input--disable-focus-styling:focus {
    & ~ .option__label {
        outline: none;
    }
}

.option--checked {
    background-color: color(primary);
    border-color: color(primary);
    color: $color-links-secondary;
    &:hover {
        background-color: color(primary);
            border-color: color(primary);
    }
    .option__suffix {
        color: color(secondary);
    }
    .option__icon {
        display: inline-block;
        vertical-align: middle;
        margin-left: $spacing--small;
        margin-right: -$spacing--xsmall;
    }
    .option__label-icon svg {
        fill: color(secondary);
    }
}

.option--primary {
    border-color: color(primary);
}

.option--large {
    display: block;
    padding: 0;
    height: auto;
    border-radius: $border-radius;
    background-color: transparent;
    border-color: transparent;
    margin: 0 0 $spacing--medium;
    &:hover,
    &:focus {
        background-color: color(neutral,5);
        border: 1px solid color(neutral,5);
        background-color: transparent;
        border-color: transparent;
        .option__label-icon {
            background: $color-light;
        }
        .option__label {
            color: $color-links--hover;
        }
    }
    .option__label {
        @include font-size(base);
        color: $color-text;
        @include min-width(lg) {
            @include font-size(medium);
        }
    }
    .option__label-icon {
        background-color: color(neutral,5);
        padding: $spacing--medium;
        border-radius: 100px;
        margin-right: $spacing--medium;
    }
}
.option--large.option--checked {
    .option__label {
        color: color(primary);
    }

    .option__label-icon {
        background: color(primary);
    }
}
