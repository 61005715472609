@import '~@bokbasen/react-style-core/scss/common/helpers';

.fieldset {
    margin-bottom: $spacing--large;
    border: none;
    padding: 0;
}

.fieldset__legend {
    @include font(default, bold);
    @include font-size(base);
    margin-bottom: $spacing--medium;
    width: 100%;
}
