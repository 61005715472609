@import '~@bokbasen/react-style-core/scss/common/helpers';

.gettingstarted__preamble, .account__suffix {
    @include font(default, base);
    @include font-size(medium);
    color: $color-text;
    margin: 0 0 $spacing--large 0;
}

.gettingstarted__suffix {
    margin-top: $spacing--large;
}

.gettingstarted {
    svg {
        path {
            fill: color(primary);
        }
    }
}