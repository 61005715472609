@import '~@bokbasen/react-style-core/scss/common/helpers';

.message {
    display: block;
    position: relative;
    border: 1px solid color(neutral, 10);
    background: color(neutral, 10);
    color: $color-text;
    border-radius: $border-radius;
    @include font-size(base);
    margin-bottom: $spacing--large;
    animation: message-in 600ms $ease-out-quart both;
}

.message__container {
    padding: $spacing--large;
}

.message__header {
    @include font-size(large);
    margin: 0;
    margin-bottom: $spacing--small;
}

.message__content {
    @include font-size(medium);
    > *:last-child {
        margin-bottom: 0;
    }
}

.message__options {
    position: absolute;
    top: $spacing;
    right: $spacing;
}

.message--warning {
    border-color: color(negative);
    background: color(negative);
    color: $color-light;
    animation: message-warning-in 600ms $ease-out-quart both;
    svg {
        fill: $color-light;
    }
    p {
        color: $color-light;
    }
}

.message--information {
    border-color: color(secondary);
    background: color(secondary);
}

.message--fullwidth, .message--fullpage {
    margin-bottom: 0;
    border-radius: 0;
    animation: message-fullwidth-in 900ms $ease-out-quart both;
    overflow: hidden;
    max-height: 66vh;
    .message__container {
        max-width: $container-width--small;
        margin: 0 auto;
        padding: $spacing--xlarge $spacing;
        animation: message-in 300ms $ease-out-quart both;
        animation-delay: 200ms;
    }
    .message__options {
        animation: message-in 300ms $ease-out-quart both;
        animation-delay: 200ms;
    }
}

.message--fullpage {
    max-height: none;
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.message--small {
    .message__container {
        padding: $spacing;
    }
    .message__header {
        display: inline;
        @include font-size(base);
        @include font(default, normal);
        margin: 0;
        padding-right: $spacing--small;
    }
    .message__content {
        display: inline;
        @include font-size(base);
    }
}

@keyframes message-in {
    from {
        opacity: 0;
        transform: translate(0) rotateX(45deg);
        transform-origin: top;
    }
}

@keyframes message-fullwidth-in {
    from {
        opacity: 0;
        max-height: 0;
    }
}

@keyframes message-warning-in {
  0% {
    opacity: 0;
  }
  10%, 90% {
     transform: translate(-0.1rem, 0);
   }

   20%, 80% {
     transform: translate(0.2rem, 0);
   }

   30%, 50%, 70% {
     transform: translate(-0.4rem, 0);
   }

   40%, 60% {
     transform: translate(0.4rem, 0);
   }
   100% {
     transform: translate(0, 0);
     opacity: 1;
   }
}

@keyframes message-bounce-in {
    0%{
        opacity: 0;
        transform: scale(0.7) translate3d(0,0,0);
    }
    50%{
        opacity: 0.9;
        transform: scale(1.05);
    }
    80%{
        opacity: 1;
        transform: scale(0.95);
    }
    100%{
        opacity: 1;
        transform: scale(1) translate3d(0,0,0);
    }
}