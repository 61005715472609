@import '~@bokbasen/react-style-core/scss/common/helpers';

.user-profile {
    padding: $spacing--xlarge 0;
    position: relative;
}

.user-profile figure {
    margin: 0 0 $spacing--large;
}

.user-profile p {
    @include font-size(medium);
    color: $color-text;
}

.user-profile__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.user-profile__parent {
    position: relative;
    margin-bottom: $spacing;
    left: -($spacing--small);

    @include min-width(xl) {
        position: absolute;
        left: $spacing;
        top: $spacing--xlarge;
    }
}

.user-profile__group {
    background: $color-light;
    display: block;
    border-radius: $border-radius;
    margin-bottom: $spacing--large;
    padding: $spacing--large $spacing;

    @include min-width(md) {
        padding: $spacing--large;
    }
}

.user-profile__group-heading {
    color: color(primary);
    margin-bottom: $spacing;
}

.user-profile__group-content {
    margin-bottom: $spacing--large;
}

.user-profile__group-footer {
    margin-top: $spacing--large;
}

.user-profile__group p {
    @include font(default, normal);
    @include font-size(base);
}

.user-profile__inactive {
    @include font(default, normal);
    @include font-size(small);
    color: color(neutral, 50);
}

.user-profile__itemlist {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.user-profile__itemlist--detail {
    margin-bottom: $spacing--xsmall;
    padding-right: $spacing--large;
    flex-basis: 100%;
    width: 100%;
    text-align: left;
}

.user-profile__itemlist--infoicon {
    padding-left: 0.5rem;
}

.user-profile__itemlist--detaillabel {
    margin-bottom: $spacing--medium;
    @include font-size(small);
    color: color(neutral, 70);
}

.user-profile__itemlist--detailvalue {
    @include font(default, normal);
    @include font-size(base);
    flex-basis: 50%;
    max-width: 50%;
    width: 50%;
    text-align: left;
    display: inline-flex;
}

.user-profile--library-dropdown {
    @include font(default, normal);
    border: $border-width solid color(neutral, 5);
    background: color(neutral, 5);
    border-radius: $border-radius;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    width: content-box;
    transition: border 300ms $ease-out-expo;


    &:focus {
        outline: none;
        border: 1px solid $color-focus;
    }
}

.user-profile__small-text {
    @include font-size(medium);
    color: $color-text;
    text-align: center;
}

.user-profile__delete-receipt {
    color: $color-text;
    margin-left: $spacing--small;
    p {
        font-weight: bold;
        margin-bottom: $spacing--small;
    }
}

.user-profile__email-note {
    line-height: 1.95rem;
}