@import '~@bokbasen/react-style-core/scss/common/helpers';

.book {
  display: flex;
  margin: 0;
  position: relative;

  & .book__title {
    @include font(default, bold);
    @include font-size(base);
    margin-bottom: $spacing--small;

    @include min-width(sm) {
      @include font-size(medium);
    }
  }

  & .book__title-link {
    color: color(primary);
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: color 300ms $ease-out-quart, border-bottom-color 300ms $ease-out-quart;
    &:hover,
    &:focus {
      color: color(primary, light);
      border-bottom-color: color(primary, light);
    }
  }

  & .book__authors {
    @include font-size(small);
    margin-bottom: $spacing--small;

    @include min-width(sm) {
      @include font-size(base);
    }

    svg {
      fill: color(neutral, base);
    }
  }

  & .book__author-link {
    color: $color-text;
    border-bottom: 1px dashed color(neutral, 50);

    &:hover, &:focus {
      cursor: pointer;
      color: $color-links--hover;
      border-bottom-color: $color-links--hover;
    }
  }

  & .book__author {
    margin-right: $spacing--medium;
    white-space: nowrap;
  }

  & .book__year-published {
    //margin-left: $spacing--medium;
  }

  & .book__children {
    margin-top: 0;
  }

  & .book__options {
    position: absolute;
    bottom: 0;
    right: $spacing;

    @include min-width(md) {
      right: $spacing--large;
    }
  }

  & .book__cover {
    position: relative;
    animation: fade-in 150ms $ease-out-quart backwards;

    img, > svg {
      box-shadow: 0 12px 30px -10px rgba(22, 36, 58, 0.2);
      border-radius: 3px;
      transition: all 300ms ease;
    }
  }

  & .book__cover-new {
     position: absolute;
     width: 100%;

     span {
       position: absolute;
       display: block;
       @include font(default, bold);
       @include font-size(xsmall);
       color: color(primary);
       text-align: center;
       left: $spacing--large;
       top: 0;
       height: 32px;
       width: 100%;
       line-height: 24px;
       z-index: 1;
     }

     svg {
       position: relative;
       left: $spacing--large;
       z-index: 1;
       fill: color(secondary);
       margin-top: -3px;
       height: 32px;
       width: 32px;
     }
   }

  & .book__cover--typeicon {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: right;
    svg {
      position: absolute;
      bottom: $spacing--xsmall;
      left: $spacing--xsmall;
      z-index: 1;
      background-color: rgba(255,255,255,0.6);
      padding: 3px;
      border-radius: 70%;
      fill: color(primary);
      margin-top: -3px;
      height: 32px;
      width: 32px !important;
      min-width: 32px !important;
    }
  }

  & .book__cover-placeholder {
    position: absolute;
    top: 3rem;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;

    svg {
      fill: color(neutral, 20);
    }
  }
}

.book--new {

}

.book--expired {
  & .book__cover {
    img, svg{
      opacity: 0.5;
      filter: grayscale(1);
    }
  }
}

.book--list,
.book--list-cart {

  & .book__cover {
    max-height: 6rem;
    flex-basis: $book-cover--xxsmall;
    width: $book-cover--xxsmall;
    min-width: $book-cover--xxsmall;
    align-items: flex-start;

    a {
      border: none;
    }

    @include min-width(sm) {
      max-height: 8rem;
      flex-basis: $book-cover--xsmall;
      width: $book-cover--xsmall;
      min-width: $book-cover--xsmall;
    }

    @include min-width(md) {
      max-height: 11rem;
      flex-basis: $book-cover--medium;
      width: $book-cover--medium;
      min-width: $book-cover--medium;
    }

    @include min-width(lg) {
      max-height: 13rem;
      flex-basis: $book-cover;
      width: $book-cover;
      min-width: $book-cover;
    }
  }

  & .book__content {
    flex-basis: 100%;
    width: 100%;
    justify-content: flex-start;
    padding: 0 0 0 $spacing;

    @include min-width(md) {
      padding: 0 0 0 $spacing + $spacing--small;
      flex-basis: 60%;
      width: 60%;
    }
  }

  & .book__price-summary {
    position: absolute;
    right: 0;
    top: 0;

    strong {
      margin-left: $spacing;
      @include font(default, bold);
      @include font-size(base);

      @include min-width(sm) {
        @include font-size(medium);
      }
    }
  }

  & .book__children {
    margin-top: $spacing;
  }
}

.book--list-cart {

  & .book__cover {
    flex-basis: $book-cover--xxsmall;
    width: $book-cover--xxsmall;
    min-width: $book-cover--xxsmall;

    @include min-width(sm) {
      flex-basis: $book-cover--xxsmall;
      width: $book-cover--xxsmall;
      min-width: $book-cover--xxsmall;
    }

    @include min-width(md) {
      flex-basis: $book-cover--xsmall;
      width: $book-cover--xsmall;
      min-width: $book-cover--xsmall;
    }

    @include min-width(lg) {
      flex-basis: $book-cover--xsmall;
      width: $book-cover--xsmall;
      min-width: $book-cover--xsmall;
    }
  }

  & .book__content {
    @include min-width(md) {
      flex-basis: 75%;
      width: 75%;
    }
  }

  & .book__author {
    @include font-size(small);
    margin-top: -($spacing--xsmall);
    width: 100%;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include min-width(sm) {
      @include font-size(base);
    }
  }

  & .book__author + .book__author {
    margin-left: 0;
    margin-top: 0;
  }
}

.book--cover {
  margin: 0;
  display: block;
  max-width: 203px;
  vertical-align: top;
  animation: fade-in 150ms $ease-out-quart backwards;
  @include animation-delay(24, 30ms, 60ms);

  &:hover {

    .book__cover {
      transform: scale(1.02);

      > img, > svg{
        box-shadow: 0 12px 40px -10px rgba(22, 36, 58, 0.4);
      }

      //box-shadow: 0 10px 20px rgba(0, 0, 0, .2), 0 6px 6px rgba(0, 0, 0, .2);
      //box-shadow: 0 12px 30px -10px rgba(0, 0, 0, 0.2);
    }
  }

  & .book__cover {
    flex-basis: 100%;
    margin-bottom: $spacing;
    display: flex;
    height: 13rem;
    align-items: flex-end;
    flex-basis: $book-cover--small;
    width: $book-cover--small;
    min-width: $book-cover--small;
    transition: all 300ms $ease-out-quart;

    @include min-width(sm) {
      flex-basis: $book-cover--medium;
      width: $book-cover--medium;
      min-width: $book-cover--medium;
      height: 13rem;
    }

    @include min-width(xl) {
      flex-basis: $book-cover;
      width: $book-cover;
      min-width: $book-cover;
      height: 14rem;
    }

    img, svg{
      width: auto;
      max-width: 100%;
      min-width: $book-cover;
    }

    a {
      border: none;
    }
  }

  & .book__cover-placeholder {
    top: 50%;
  }

  & .book__content {
    flex-basis: 100%;
    width: 100%;
    padding: 0 $spacing 0 0;

    @include min-width(md) {
      padding: 0 $spacing--large 0 0;
    }
  }

  & .book__title {
    @include font(default, bold);
    @include font-size(base);
    margin-bottom: 0;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
  }

  & .book__author {
    @include font-size(small);
    margin-top: -($spacing--xsmall);
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }

  & .book__author + .book__author {
    margin-left: 0;
    margin-top: 0;
  }
}


.book--primary {
  color: $color-light;
  margin: 0;
  flex-basis: 100%;
  width: 100%;
  align-items: center;
  position: initial;

  & .book__cover {
    flex-basis: 100%;
    margin-bottom: 0;
    display: flex;
    position: relative;
    height: 10rem;
    margin-left: $spacing--medium;
    align-items: flex-end;
    flex-basis: $book-cover--small;
    width: $book-cover--small;
    min-width: $book-cover--small;
    transition: all 300ms $ease-out-quart;

    @include min-width(sm) {
      flex-basis: $book-cover--medium;
      width: $book-cover--medium;
      min-width: $book-cover--medium;
      height: 12rem;
    }

    @include min-width(md) {
      margin-left: $spacing--large;
    }

    @include min-width(lg) {
      flex-basis: $book-cover--large;
      width: $book-cover--large;
      min-width: $book-cover--large;
      height: 14rem;
    }

    img, svg{
      width: auto;
      max-width: 100%;
      display: block;
      margin: 0;
      //box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.5);
    }

    a {
      border: none;
    }
  }

  & .book__content {
    padding: 0 $spacing;
    flex-basis: 100%;
    width: 100%;

    @include min-width(lg) {
      padding: 0 $spacing--large;
    }
  }

  & .book__title {
    @include font(default, bold);
    @include font-size(base);
    margin-bottom: $spacing--xsmall;

    @include min-width(xs) {
      @include font-size(medium);
    }

    @include min-width(md) {
      @include font-size(large);
    }
  }

  & .book__title-link {
    color: $color-light;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: color 300ms $ease-out-quart, border-bottom-color 300ms $ease-out-quart;
    &:hover,
    &:focus {
      color: $color-light;
      border-bottom-color: $color-light;
    }
  }

  & .book__authors {
    @include font(default);
    @include font-size(base);
    margin-bottom: $spacing;
    color: $color-light;
  }

  & .book__options {
    position: absolute;
    top: $spacing;
    bottom: auto;
    right: $spacing;

    button svg {
      fill: $color-light;
    }
  }
}


.book--primary-small {
  display: flex;
  color: $color-light;
  margin: 0;
  flex-basis: 100%;
  width: 100%;
  height: 100%;
  align-items: flex-end;

  & .book__cover {
    flex-basis: 100%;
    margin-bottom: 0;
    display: flex;
    position: relative;
    margin-left: $spacing;
    align-items: flex-end;
    flex-basis: $book-cover--small;
    width: $book-cover--small;
    min-width: $book-cover--small;
    height: 12rem;

    transition: all 300ms $ease-out-quart;

    @include min-width(xs) {
      flex-basis: $book-cover--medium;
      width: $book-cover--medium;
      min-width: $book-cover--medium;
      height: 12rem;
      margin-left: $spacing;
    }

    @include min-width(lg) {
      flex-basis: $book-cover;
      width: $book-cover;
      min-width: $book-cover;
      height: 14rem;
      margin-left: $spacing--large;
    }


    @include min-width(lg) {
      flex-basis: $book-cover--medium;
      width: $book-cover--medium;
      min-width: $book-cover--medium;
      height: 12rem;
      margin-left: $spacing--large;
    }

    @include min-width(xl) {
      flex-basis: $book-cover;
      width: $book-cover;
      min-width: $book-cover;
      height: 14rem;
      margin-left: $spacing--large;
    }

    img, svg{
      width: auto;
      max-width: 100%;
      display: block;
      margin: 0;
      //box-shadow: 0 12px 24px -10px rgba(0, 0, 0, 0.5);
    }

    a {
      border: none;
    }

  }

  & .book__content {
    padding: 0 $spacing;
    flex-basis: 100%;
    width: 100%;
    height: 10rem;

    @include min-width(xs) {
      padding: 0 $spacing--large;
    }

    @include min-width(xl) {
      padding: 0 $spacing--large;
      height: 12rem;
    }
  }

  & .book__title {
    @include font(default, bold);
    @include font-size(base);
    margin-bottom: $spacing--xsmall;

    @include min-width(xs) {
      @include font-size(medium);
    }

  }

  & .book__title-link {
    color: $color-light;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: color 300ms $ease-out-quart, border-bottom-color 300ms $ease-out-quart;
    &:hover,
    &:focus {
      color: $color-light;
      border-bottom-color: $color-light;
    }
  }

  & .book__author {
    @include font(default);
    @include font-size(base);
    margin-bottom: $spacing;
    color: $color-light;
  }

  & .book__options {
    position: absolute;
    top: $spacing;
    bottom: auto;
    right: $spacing;

    button svg {
      fill: $color-light;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}