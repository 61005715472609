@import '~@bokbasen/react-style-core/scss/common/helpers';

.createaccount__preamble {
   @include font(default, base);
   @include font-size(medium);
   color: $color-text;
   margin: 0 0 $spacing--large 0;
 }

.createaccount__supporttext {
  margin-left: $spacing--large;

}

.createaccount__supporttext, .createaccount__text {
  svg {
    margin-left: 0.5em;
    display: inline;
    fill: none;
    stroke: $color-links;
    stroke-width: 3;
    vertical-align: middle
  }
}