@import '~@bokbasen/react-style-core/scss/common/helpers';

.section-toggle-group {
    display: flex;
    flex-direction: column;
    > * {
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
}