@import '~@bokbasen/react-style-core/scss/common/helpers';

.textarea {
  @include font(default, normal);
  background: $color-light;
  border-radius: $border-radius;
  border: $border-width solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing;
  width: 100%;
  box-sizing: border-box;
  transition: border 300ms $ease-out-expo;
  &:focus {
    outline: none;
    border-color: $color-focus;
  }
}

.textarea--no-focus {
    border: none;
}

.textarea--small {
    padding: $spacing--small $spacing--small;
}