@import '~@bokbasen/react-style-core/scss/common/helpers';

.reader-styles :global {

    .loading {
      position: fixed;
      width: 100%;
      height: 100vh;
      top: 0;
      z-index: $level-2;
      user-select: none;
      cursor: default;
      background-color: $color-backdrop--light;

      .icon {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 34px; // emulate 28px size until we have replaced icon
        height: 34px; // emulate 28px size until we have replaced icon
        transform: translate(-50%, -50%);
        fill: $color-links;
      }

    }

    .loading.is-loading .icon {
        animation: loadingSpin 1s ease-in-out infinite;
    }
}

[data-viewer-theme="night"] .reader-styles :global {
  .loading {
      background-color: $color-backdrop;
      .icon {
          fill: $color-light;
      }
    }
}

@keyframes :global(loadingSpin) {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}