@import '~@bokbasen/react-style-core/scss/common/helpers';

.product-toggle {
    //border: 1px solid color(neutral, 10);
    margin-bottom: $spacing--medium;

}

.product-toggle__trigger {
    @include font(default, bold);
    @include font-size(medium);
    border-radius: $border-radius;
    display: flex;
    justify-content: space-between;
    padding: $spacing;
    width: 100%;
    background: $color-light;

    span {
        justify-content: center;
        text-align: left;
    }

    @include min-width(md) {
        @include font(default, bold);
        @include font-size(medium);
    }
}

.product-toggle__trigger:active, .product-toggle__trigger:focus {
    background: $color-light;
    color: color(primary);
}

/* Disable focus outline on mouse and touch interaction without loosing the accessibility feature of focus */
.product-toggle__trigger:active, .product-toggle__trigger--disable-focus-styling {
    outline: none;
}

.product-toggle__price {
    @include font(default, bold);
    color: color(primary);
}

.product-toggle__content {
    padding: 0 $spacing $spacing $spacing;
    background: $color-light;
    display: none;
    border-radius: $border-radius;
}

.product-toggle__content p {
    @include font-size(base);
    margin-bottom: $spacing;
    color: $color-text;
}

.product-toggle--open .product-toggle__trigger {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.product-toggle--open .product-toggle__content {
    display: block;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.product-toggle--transparent .product-toggle__trigger {
    background: transparent;
    &:hover {
        background: color(neutral,10);
    }
}

.product-toggle--transparent.product-toggle--open .product-toggle__trigger {
    background: $color-light;
}
