@import '~@bokbasen/react-style-core/scss/common/helpers';

.section-toggle {
    @include max-width(md) {
        margin-bottom: $spacing;
        margin-left: -$spacing;
        margin-right: -$spacing;
    }
}

.section-toggle__trigger {
    display: none;
    @include max-width(md) {
        @include font(default, normal);
        @include font-size(base);
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: $spacing--medium $spacing;
        border-bottom: 1px solid color(neutral, 20);
    }
}

.section-toggle__trigger:active, .section-toggle__trigger--disable-focus-styling {
    outline: none;
}

@include max-width(md) {
    .section-toggle__content {
        padding: $spacing--medium $spacing;
        display: none;
    }

    .section-toggle--open {
        background: color(neutral, 10);
    }

    .section-toggle--open .section-toggle__trigger {
        position: sticky;
        top: 76px;
        background: $color-light;
        z-index: $level-1;
    }

    .section-toggle--open .section-toggle__content {
        display: block;
    }

    .section-toggle--small:not(.section-toggle--open) .section-toggle__trigger {
        width: auto;
        border-bottom: none;
    }
}