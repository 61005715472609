@import '~@bokbasen/react-style-core/scss/common/helpers';

.reader-styles :global {

    .reader-icon {
        display: inline-block;
        vertical-align: middle;
    }

    .sidenav-annotations {
        margin-bottom: $spacing--large;
        li {
            position: relative;
        }

        .chapter-link {
            font-weight: bold;
        }
    }

    .annotations__chapter-header {
        font-size: fontSize(small);
        padding: $spacing--medium;
        border-bottom: 1px solid color(neutral,10);
    }

    .annotations__chapter-link {
        color: $color-text;
        display: block;
        width: 100%;
    }

    .annotation-item {
        position: relative;
        .delete {
            display: none;
            position: absolute;
            top: $spacing--small;
            right: $spacing--small;
            padding: $spacing--xsmall;
            border-radius: $border-radius--small;
            background: $color-light;
            cursor: pointer;
            color: color(neutral,50); // TODO: Update color setting with SVG icons
            &:hover, &:focus {
                background: color(neutral,5);
                color: color(negative);  // TODO: Update color setting with SVG icons
            }
        }
        &:hover, &:focus-within {
            .delete {
                display: block;
            }
        }
    }

    .bookmark-link, .note-link, .highlight-link {
        display: block;
        width: 100%;
        padding: $spacing--medium  $spacing--medium;
        border-bottom: none;
        color: $color-text;
        line-height: 1.2; // To get highlight bars to merge
        border-radius: $border-radius--small;
        &:hover, &:focus {
            color: color(primary);
            background-color: color(neutral,5);
            outline: none;
        }
        .reader-icon {
            display: none;
        }
        .title {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .subtitle, .timestamp {
            margin-top: $spacing--small;
            display: inline-block;
            margin-right: $spacing--medium;
            @include font-size(small);
            color: $color-text--light;
        }
        &:hover, &:focus {
            fill: color(primary);
        }
    }

    .highlight-link .title {
        display: block;
        max-height: 5.5em; // Four lines
        white-space: normal;
        position: relative;
        @include font-size(small);
        color: $color-text;

        /*&:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3rem;
            background: linear-gradient(rgba($color-light, 0), rgba($color-light, 1));
            pointer-events: none;
        }*/
    }

    .annotations__delete {
        display: none;
        position: absolute;
        top: $spacing--medium;
        right: 0;
        background: $color-light;
        svg {
            fill: color(primary);
        }
    }
    .annotations li:hover > .annotations__delete, .annotations li:focus-within > .annotations__delete {
        display: block;
        animation: fade-in 300ms $ease-in-quart both;
    }
}

body[data-viewer-theme="sepia"] .reader-styles :global {
    .annotation-item .delete {
        background: $color-sepia;
        &:hover, &:focus {
            background: darken($color-sepia,5%);
        }
    }

    .bookmark-link, .note-link, .highlight-link {
        &:hover, &:focus {
            background-color: darken($color-sepia,5%);
        }
    }
}

body[data-viewer-theme="night"] .reader-styles :global {
    .annotation-item .delete {
        background: $color-night;
        color: color(neutral,40); // TODO: Update color setting with SVG icons
        &:hover, &:focus {
            background: color(neutral,base);
            color: color(negative);  // TODO: Update color setting with SVG icons
        }
    }

    .annotations__chapter-link {
        color: $color-light;
    }

    .bookmark-link, .note-link, .highlight-link {
        color: $color-light;
        &:hover, &:focus {
            color: color(secondary);
            background-color: color(neutral,base);
        }
    }

    .subtitle, .timestamp {
        color: color(neutral, 30);
    }

    .highlight-link .title {
        color: $color-light;
    }
}