@import '~@bokbasen/react-style-core/scss/common/helpers';

.ident {
    display: block;
    background: color(primary);
    padding: 26.5px;
    border-radius: $border-radius;
    position: relative;

    svg {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 28px;

        path {
            fill: color(secondary);
        }
    }

    a:focus svg {
        outline: inherit;
        outline-offset: 10px;
    }
}

.ident--subtle {
    background-color: transparent;

    &:hover, &:focus {
        background: color(neutral, 10);
        background: $color-light;
    }

    svg {
        path {
            fill: color(primary);
        }
    }
}

.ident--animated svg {
    left: 52%;
    max-width: 44px;
}