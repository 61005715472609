@import '~@bokbasen/react-style-core/scss/common/helpers';

.section {
    display: block;
    padding-right: 0;
    margin-bottom: $spacing--xlarge;

    @include min-width(md) {
        padding-right: $spacing--large;
        margin-bottom: $spacing--xlarge;
    }
}

.section--regular {
    width: 100%;

    @include min-width(md) {
        width: 66.7%;
    }
}

.section--full {
    width: 100%;
    padding-right: 0;
}

.section__title {
    @include font(default, bold);
    @include font-size(medium);
    margin-bottom: $spacing--medium;

    @include min-width(lg) {
        margin-bottom: $spacing;
    }
}

.section--title-in-gutter > .section__title {
    @include min-width(lg) {
        margin-left: calc(-50% - 16px);
        float: left;
    }
}

.section--title-in-gutter.section--full > .section__title {
    @include min-width(lg) {
        margin-left: calc(-33.333%);
    }
}