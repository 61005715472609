@import '~@bokbasen/react-style-core/scss/common/helpers';
.reader-styles :global {

    .sidenav-toc {
        margin-bottom: $spacing--large;
        > li > ul, > li > ol {
            padding-left: $spacing--large + 0.1rem;
            @include font-size(small);
        }
    }

    .chapter-link {
        display: block;
        width: 100%;
        padding: $spacing--medium;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        justify-content: flex-start;
        align-items: center;
        color: $color-text;
        border-radius: $border-radius--small;
        transition: background-color 300ms $ease-out-quart;

        &.active {
            color: color(primary);
            font-weight: bold;
        }
        &:hover, &:focus {
            color: color(primary);
            background-color: color(neutral,5);
            outline: none;
        }
    }
}

body[data-viewer-theme="sepia"] .reader-styles :global {

    .chapter-link {
        &:hover, &:focus {
            background-color: darken($color-sepia,5%);
        }
    }
}

body[data-viewer-theme="night"] .reader-styles :global {

    .chapter-link {
        color: $color-light;
        &:hover, &:focus {
            color: color(secondary);
            background-color: color(neutral,base);
        }
    }
}