@import "~@bokbasen/react-style-core/scss/common/helpers";

.welcome-content {

  & > * {
    animation: lp-opacity 500ms $ease-out-quart both;
    @include animation-delay(5, 50ms, 100ms);
  }
}

.welcome-content_infotext {
  @include font(default, bold);
  @include font-size(large);
  color: $color-light;
  margin: $spacing--large 0;
  text-align: center;
  @include font-size(large);
  font-weight: 500;
  font-size: 1.5rem;
  @include min-width(sm) {
    text-align: left;
  }
  a {
    color: color(secondary);
    &:hover {
      color: color(secondary);
      outline: none;
    }
  }
}

.welcome-content__promocontent {
  grid-template-columns: 20% 80%;
}
.welcome-content__supporttext {
  margin-top: $spacing--medium;
  margin-left: $spacing--small;
  @include font-size(medium);
  a {
    color: color(secondary);
    &:hover {
      color: color(secondary);
      outline: none;
    }
  }
}

.welcome-content__buttonspace {
  padding-left: $spacing;
  @include min-width(xs) {
    padding-left: $spacing--large;
  }
}

@keyframes lp-opacity {
  from {
    opacity: 0;
  }
}