@import '~@bokbasen/react-style-core/scss/common/helpers';

.color-option {
    height: 24px;
    width: 24px;
    border-radius: 24px;
    display: flex;
    margin-right: $spacing--xsmall;
    border: 3px solid $color-light;
    box-shadow: 0 0 0 2px transparent;
    animation: marker-bounceIn 500ms $ease-out-expo both;
    @include animation-delay(12, 30ms, 200ms);
    @include min-width(lg) {
        height: 24px;
        width: 24px;
        border-radius: 24px;
    }
    &:focus, &:active {
        box-shadow: 0 0 0 2px color(primary);
        outline: none;
    }
}

.color-option--selected {
    box-shadow: 0 0 0 2px color(neutral, 30);
}
