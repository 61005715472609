@import '~@bokbasen/react-style-core/scss/common/helpers';

.toolbox {
    background: color(neutral, base);
    padding: $spacing--xsmall;
    border-radius: $border-radius;
    position: absolute;
    z-index: $level-2;
    white-space: nowrap;
    transform: translate(-50%, -100%);
    animation: toolbox-content-in 150ms $ease-out-expo both;
    transform-origin: bottom;
    color: $color-light;
    outline: none;
    @include font(default);
    @include font-size(xsmall);

    &:before {
        $size: 0.4rem;
        content: '';
        position: absolute;
        width: $size;
        height: $size;
        top: -($size / 2);
        left: calc(50% - #{$size / 2});
        background-color: inherit;
        transform: rotate(45deg);
    }
}

.toolbox__mode {
    animation: toolbox-mode-in 150ms $ease-out-expo both;
}

body[data-viewer-theme="night"] .toolbox--appearance-styling {
    background: $color-light;
}

@keyframes toolbox-mode-in {
    from {
        opacity: 0;
    }
}

@keyframes toolbox-content-in {
    from {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
    }
}
