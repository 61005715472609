@import "~@bokbasen/react-style-core/scss/common/helpers";

.icon {
    height: 26px;
    width: 26px;
    max-width: none;
    fill: $color-links;
    pointer-events: none;

    @include min-width(lg) {
        height: 28px;
        width: 28px;
    }
}

.icon--small {
    height: 16px;
    width: 16px;

    @include min-width(lg) {
        height: 20px;
        width: 20px;
    }
}

.icon--small.icon--inline {
    margin-right: $spacing--xsmall;
    @include min-width(md) {
        margin-right: $spacing--small;
    }
}

.icon--small.icon--inline-right {
    margin-left: 0;
}

.icon--large {
    height: 28px;
    width: 28px;

    @include min-width(lg) {
        height: 32px;
        width: 32px;
    }
}

.icon--xsmall {
    height: 16px;
    width: 16px;
}

.icon--xsmall.icon--inline {
    margin-right: $spacing--xsmall;
    @include min-width(md) {
        margin-right: $spacing--xsmall;
    }
}

.icon--xsmall.icon--inline-right {
    margin-left: 0;
}

.icon--inline {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-top: 0px;
    margin-right: $spacing--medium;

    @include min-width(md) {
        margin-top: -1px;
    }
}

.icon--inline-right {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-top: 0px;
    margin-left: 0;

    @include min-width(md) {
        margin-top: -1px;
    }

}

.icon--secondary {
    stroke: color(primary) !important;
    fill: none;
}

.icon--tertiary {
    stroke: color(secondary) !important;
    fill: none;
}
