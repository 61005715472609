@import '~@bokbasen/react-style-core/scss/common/helpers';

.reader-styles :global {
    .left-gutter {
        position: absolute;
        top: 0;
        left: 0;
        i {
            margin-top: $spacing--large;
            border-radius: 50%;
            padding: $spacing--small;
            transition: background-color 150ms $ease-in-quart;
            &:hover, &:focus {
                background-color: color(neutral, 5);
            }
            @include min-width(md) {
                background-color: $color-light;
            }
        }
        svg {
            max-width: 20px;
            height: 20px;
            fill: color(primary)
        }
    }

    .right-gutter {
        position: absolute;
        top: 0;
        right: 0;
    }

    .comment {
        display: block;
        position: absolute;
        width: 250px;
        padding: $spacing--medium;
        border-radius: $border-radius;
        @include font-size(small);
    }

    .comment-text {
        margin-top: $spacing--xsmall;
    }
}

body[data-viewer-theme="sepia"] .reader-styles :global {
    .left-gutter {
        i {
            background-color: $color-sepia;
            &:hover, &:focus {
                background-color: darken($color-sepia, 5%);
            }
        }
    }
}

body[data-viewer-theme="night"] .reader-styles :global {
    .left-gutter {
        i {
            background-color: $color-night;
            &:hover, &:focus {
                background-color: color(neutral, 80);
            }
        }
        svg {
            fill: color(secondary);
        }
    }
}
