@import '~@bokbasen/react-style-core/scss/common/helpers';

.page-layout {
    position: relative;
    padding: 0 $spacing;

    @include min-width(md) {
        display: grid;
        grid-template-columns: [main] 1fr [sidebar] 1fr;
        grid-template-rows: auto;
        //grid-column-gap: $spacing--large;
        max-width: $container-width--large;
        width: 100%;
        margin: 0 auto;
    }

    @include min-width(lg) {
        grid-template-columns: [lead] 1fr [main] 2fr [sidebar] 1fr;
    }
}

.page-layout--3 {
    grid-template-columns: [lead] 1fr [main] 1fr [sidebar] 1fr;
}

.page-layout__main, .page-layout__main-left, .page-layout__main-right, .page-layout__sidebar, .page-layout__lead, .page-layout__head, .page-layout__bar  {
    padding: 0;
    @include min-width(md) {
        width: 100%;
    }
}

.page-layout__main, .page-layout__main-left, .page-layout__main-right, .page-layout__head {
    //margin-bottom: $spacing--large;
}

.page-layout__sidebar, .page-layout__lead {
    //margin-bottom: $spacing--large;
}

.page-layout__head {
    @include min-width(md) {
        grid-column-start: lead;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 1;
    }
}

.page-layout__main {
    @include min-width(md) {
        grid-column-start: main;
        grid-column-end: sidebar;
        grid-row-start: 2;
        grid-row-end: 2;
    }
}

.page-layout__main-left {
    @include min-width(md) {
        grid-column-start: lead;
        grid-column-end: sidebar;
        grid-row-start: 2;
        grid-row-end: 2;
    }
}

.page-layout__main-right {
    @include min-width(md) {
        grid-column-start: main;
        grid-column-end: 4;
        grid-row-start: 2;
        grid-row-end: 2;
    }
}

.page-layout__lead {
    @include min-width(md) {
        grid-column-start: lead;
        grid-column-end: main;
        grid-row-start: 2;
        grid-row-end: 2;
    }
}

.page-layout__sidebar {
    @include min-width(md) {
        grid-column-start: sidebar;
        grid-row-start: 2;
        grid-row-end: 2;
    }
}

.page-layout__bar {
    margin-bottom: $spacing--medium;
    @include min-width(md) {
        grid-column-start: lead;
        grid-column-end: 4;
        grid-row-start: 3;
        grid-row-end: 3;
    }
}

.page-layout--fluid {
    max-width: none;
    margin: 0;
}

.page-layout--spacing {
    margin-top: $spacing--xlarge;
}

.page-layout__sidebar--sticky {
    @include min-width(md) {
        position: sticky;
        top: 4.75rem;
        height: 100vh;
        overflow: auto;
    }
}
