@import '~@bokbasen/react-style-core/scss/common/helpers';

.link {
    color: $color-links;
    text-decoration: none;
    white-space: nowrap;
    border-bottom: $border-width--large solid rgba($color-links--hover, 0.3);
    transition: color 300ms $ease-out-quart;
    &:hover,
    &:focus {
        color: $color-links--hover;
        border-bottom: $border-width--large solid $color-links--hover;
    }
}


.link--inverted {
    color: color(secondary);
    text-decoration: none;
    white-space: nowrap;
    border-bottom: $border-width--large solid rgba(color(secondary), 0.3);
    transition: color 300ms $ease-out-quart;
    &:hover,
    &:focus {
        color: color(secondary);
        border-bottom: $border-width--large solid color(secondary);
    }
}

.link--header {
    margin-left: $spacing--small;
    margin-right: $spacing--small;
    border-bottom: none;

    &:hover, &:focus {
        border: none;
        color: color(primary, light);
    }
}
