@import '~@bokbasen/react-style-core/scss/common/helpers';

.support-bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    background: color(secondary);
    color: $color-links;
    padding: $spacing--small $spacing--medium;
    min-height: 40px;
    min-width: 40px;
    border-radius: 20px;
    position: fixed;
    z-index: $level-3;
    bottom: $spacing;
    right: $spacing;
    box-shadow: $shadow;
    transition: background 300ms $ease-out-quart;
    animation: bounce-in 300ms forwards;
    animation-delay: 600ms;
    opacity: 0;
    &:hover,
    &:focus {
      color: $color-links;
      background-color: color(secondary, dark);
    }
}

.support-bubble__content {
    @include font(default, default);
    @include font-size(base);
    padding-left: $spacing--small;
    padding-right: $spacing--xsmall;
    @include max-width(sm) {
        /* Visually hidden, but accessible for screenreaders on mobile */
        position: absolute;
        width: 1px;
        height: 1px;
        overflow: hidden;
    }
}
.support-bubble__icon {
    svg { fill: color(primary); }
}

.support-bubble--subtle {
    background-color: $color-light;
    box-shadow: $shadow--light;
    .support-bubble__content {
        overflow: hidden;
        white-space: nowrap;
        max-width: 0;
        padding: 0;
        transition: all 300ms $ease-out-quart;
    }
}

.support-bubble--subtle:hover, .support-bubble--subtle:focus {
    background-color: $color-background;
    .support-bubble__content {
        max-width: 10em;
        padding-left: $spacing--small;
        padding-right: $spacing--xsmall;
    }
}

@keyframes bounce-in {
    0%{
        opacity: 0;
        transform: scale(0.7) translate3d(0,0,0);
    }
    50%{
        opacity: 0.9;
        transform: scale(1.05);
    }
    80%{
        opacity: 1;
        transform: scale(0.95);
    }
    100%{
        opacity: 1;
        transform: scale(1) translate3d(0,0,0);
    }
}