@import '~@bokbasen/react-style-core/scss/common/helpers';

.grid {
    display: grid;
    grid-template-columns: repeat(1fr);
    grid-column-gap: 0;
    grid-row-gap: 0;
    align-items: start;
    width: 100%;
    height: 100%;

    @include min-width(lg) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.grid--dark {
    background: color(primary);
}

.grid--light {
    background: $color-light;
}

.grid--gutter {
    grid-column-gap: $spacing;
}

.grid__item {

}