@import "~@bokbasen/react-style-core/scss/common/helpers";

.loanrenewal {
}

.loanrenewal__infotext{
  animation: fade-in  1000ms $ease-in-quart both;
  @include animation-delay(12, 30ms, 200ms);
  @include font(default, bold);
  @include font-size(large);
  color: $color-light;
  margin: 0 0 $spacing--small;
  text-align: center;
  @include font-size(large);
  @include min-width(sm) {
    text-align: left;
  }
}

.loanrenewal__confirmationbox{
  display: flex
}

.loanrenewal__confirmationboxcontent{
  padding-left: 20px
}

.loanrenewal__bookcover{
  max-width: 100px
}


@keyframes lp-opacity {
  from {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }
}
