@import '~@bokbasen/react-style-core/scss/common/helpers';

.reader-styles :global {
    .timeline {
        display: none;
        position: fixed;
        top: 5rem;
        left: 1rem;
        bottom: 1rem;
        width: $spacing;
        width: 1.25rem;
        z-index: $level-1;
        @include min-width(905px) { /* Custom breakpoint to not overlap bookmark icons */
            display: flex;
            flex-direction: column;
        }
        @include min-width(xl) {
            left: 2.5rem;
        }
        .chapter {
            transition: border-color 300ms $ease-out-quart;
            background: color(neutral, 10) !important;
            border: 2px solid $color-light !important;
            border-radius: $border-radius--small;
            position: relative;
            min-height: $spacing--small;
            flex-grow: 1;
            &:hover {
                background-color: color(neutral,20) !important;
                .chapter-tooltip {
                    display: block;
                }
            }
            &.active {
                background-color: $color-links !important;
            }
        }
        .chapter-tooltip {
            display: none;
            position: absolute;
            left: 2rem;
            top: 50%;
            transform: translate(0,-50%);
            background: color(neutral,base);
            border-radius: $border-radius--small;
            padding: 0.25rem 0.5rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 22rem;
            color: $color-light;
            animation: timeline-tooltip-in 150ms $ease-out-expo both;
            animation-delay: 0.25s;
            transform-origin: left;
        }
    }
}

body[data-viewer-theme="sepia"] .reader-styles :global {
    .timeline .chapter {
        border-color: $color-sepia !important;
        background-color: darken($color-sepia, 5%) !important;
        &:hover {
            background-color: darken($color-sepia, 10%) !important;
        }
        &.active {
            background-color: $color-links !important;
        }
    }
}

body[data-viewer-theme="night"] .reader-styles :global {
    .timeline .chapter {
        border-color: $color-night !important;
        background-color: color(neutral,base) !important;
        &:hover {
            background-color: color(neutral,80) !important;
        }
        &.active {
            background-color: color(secondary) !important;
        }
    }
}

@keyframes :global(timeline-tooltip-in) {
    from {
        opacity: 0;
        transform: translate(-0.25rem,-50%)  scale(0.75) ;
    }
}
