@import '~@bokbasen/react-style-core/scss/common/helpers';

.input-group {
    display: flex;
    align-items: center;
    background: $color-light;
    border-radius: $border-radius;
    border: $border-width solid transparent;
    transition: border 300ms $ease-out-expo;
    width: 100%;
    &:focus-within {
        border-color: $color-focus;
    }
}

.input-group--large {

    input {
        border: none;
        padding: $spacing;
        @include font(default, normal);

        @include min-width(md) {
            padding: $spacing;
            @include font-size(medium);
        }
    }

    button {
        margin: 0;
    }
}

.input-group--large .input-group__button {
    padding: 0 $spacing--xsmall 0 0;
}

.input-group--subtle {
    background: color(neutral, 10);

    input {
        border: none;
        background: color(neutral, 10);
    }
}


.input-group__button {
    white-space: nowrap;
}

.input-group--small {

    & .input-group__button {
        padding: 0;

        button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
}

body[data-viewer-theme="sepia"] .input-group--appearance-styling {
    background: darken($color-sepia,5%);
    input {
        background: darken($color-sepia,5%);
    }
}

body[data-viewer-theme="night"] .input-group--appearance-styling {
    background: color(neutral,base);
    &:focus-within {
        border-color: color(secondary);
    }
    input {
        background: color(neutral,base);
        color: $color-light;
        &::placeholder {
            color: color(neutral,70);
        }
    }
}