@import '~@bokbasen/react-style-core/scss/common/helpers';

.blurry-image__background {
  position: absolute;
  top: 0;
  width: 75%;
  overflow: hidden;
  z-index: 0;
  animation: bd-bgimage 500ms $ease-out-quart both;

  //box-shadow: $shadow--light;
  & img {
    width: 100%;
    filter: blur(8px);
    opacity: 1;
  }
}

.blurry-image__background:before {
  position: absolute;
  content: "";
  z-index: 1;
  display: flex;
  top: -80%;
  left: -80%;
  width: 300%;
  height: 200%;
  transform: rotate(-52deg);
  background: linear-gradient(rgba($color-background, 0) 0%, rgba($color-background, 1) 46%);
}