@import '~@bokbasen/react-style-core/scss/common/helpers';

.reader-layout {
    min-height: 100vh;

    ::selection {
        background: #eaeaea;
    }
}

.reader-layout__navbar {
    position: fixed;
    z-index: $level-2;
    top: 0;
    left: 0;
    right: 0;
    background: $color-light;
    min-height: 4rem;
    height: 64px;
    padding: 5px;
    box-shadow: 0 0.1rem 2rem rgba(0, 0, 0, 0.1);
    &:focus {
        outline: none; // Only for programatically set focus during toggeling
    }
}

body[data-viewer-theme="sepia"] .reader-layout {
    background: $color-sepia;
}

body[data-viewer-theme="sepia"] .reader-layout__navbar {
    background: $color-sepia;
    &:after {
        background: linear-gradient(rgba($color-sepia, 1), rgba($color-sepia, 0));
    }
}

body[data-viewer-theme="night"] .reader-layout {
    background: $color-night;
}

body[data-viewer-theme="night"] .reader-layout__navbar {
    background: $color-night;
    & h1 {
        color: white !important;
    }
    &:after {
        background: linear-gradient(rgba($color-night, 1), rgba($color-night, 0));
    }
}

body[data-viewer-theme="sepia"] .reader-layout__backdrop {
    background: rgba($color-text, 0.1);
}

body[data-viewer-theme="night"] .reader-layout__backdrop {
    background: rgba($color-light, 0.1);
}

.reader-layout__menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    position: relative;
    & > * {
        flex-basis: 33%;
        max-width: 33%;
    }
    & > *:nth-child(1) {
        order: 2;
    }
    & > *:nth-child(2) {
        order: 1;
    }
    & > *:nth-child(3) {
        order: 3;
    }
}

.reader-layout__title {
    @include font(default, normal);
    @include font-size(base);
    color: $color-text;
    margin: 0;
    display: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include min-width(md) {
        display: block;
        @include font-size(medium);
    }
}


body[data-viewer-theme="night"] .reader-layout__title {
    color: $color-light;
}

.reader-layout__modal-options {
    padding: 0 $spacing;
    margin-top: $spacing;
}

.reader-layout__modal-content {
    overflow: auto;
    max-height: 100%;
    position: relative;
    padding: 0 $spacing--medium;
}

.reader-layout__navigation-list {
    ul {
        padding: 0;
        margin: 0;
    }
    a {
        padding: $spacing--small 0;
        border-bottom: 1px solid color(neutral,20);
        &:hover, &:focus {
            color: color(primary);
            border-bottom-color: color(primary);
            outline: none;
        }
    }
}

.reader-layout__navigation-modal {
    display: none;
    &:focus {
        outline: none; // Only for programatically set focus during toggeling
    }
    & > div {
        position: fixed;
        z-index: $level-3;
        top: 0rem;
        left: 0rem;
        bottom: 0rem;
        background-color: $color-light;
        box-shadow: 0 12px 60px rgba(0, 0, 0, 0.1);
        width: 22rem;
        max-width: 90%;
        @include min-width(sm) {
            width: 24rem;
        }
    }
}

body[data-viewer-theme="sepia"] .reader-layout__navigation-modal > div {
    background: $color-sepia;
}

body[data-viewer-theme="night"] .reader-layout__navigation-modal > div {
    background: $color-night;
}

.reader-layout__backdrop {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: $level-2;
    background-color: $color-backdrop;
    animation: fade-in 400ms $ease-out-expo both;
    &:focus:not(:active) {
        &:before {
            content: '×';
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: color(neutral, 80);
            color: $color-light;
            border-radius: 50%;
            width: $spacing--large;
            height: $spacing--large;
            position: absolute;
            line-height: 1;
            left: 50%;
            top: 50%;
        }
    }
}

.reader-layout__expand-trigger {
    display: none;
    position: absolute;
    top: $spacing--small;
    left: 100%;
    margin-left: $spacing--small;
    @include min-width(md) {
        display: block;
    }
}

.reader-layout__navigation-modal--show {
    display: block;
    & > div {
        animation: navigation-in 400ms $ease-out-expo both;
        transition: width 300ms $ease-out-expo;
    }
    & .reader-layout__backdrop {
        display: block;
    }
}

.reader-layout__navigation-modal--expand {
    & > div {
        width: 66.66%;
        max-width: 48rem;
    }
}


.reader-layout__settings-modal, .reader-layout__notes-modal, .reader-layout__search-modal {
    &:focus {
        outline: none; // Only for programatically set focus during toggeling
    }
    display: none;
    & > div {
        display: block;
        position: fixed;
        top: 4rem;
        z-index: $level-3;
        background: $color-light;
        border-radius: $border-radius;
        box-shadow: $shadow--light;
        width: 100%;
        max-width: 20rem;
    }
}

.reader-layout__settings-modal--show, .reader-layout__notes-modal--show, .reader-layout__search-modal--show  {
    display: block;
    & > div {
        animation: dropdown-in 400ms $ease-out-expo both;
    }
}

.reader-layout__search-modal > div {
    right: 2rem;
}

.reader-layout__settings-modal > div {
    padding: $spacing--medium;
    right: 1rem;
}

.reader-layout__notes-modal > div{
    right: 2rem;
}


body[data-viewer-theme="sepia"] .reader-layout__settings-modal > div {
    background: $color-sepia;
}

body[data-viewer-theme="night"] .reader-layout__settings-modal > div {
    background: $color-night;
}

@keyframes navigation-in {
    from {
        opacity: 0;
        transform: translateX(-4rem);
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}

@keyframes dropdown-in {
    from {
        opacity: 0;
        transform: translateY(-2rem) scale(0.5);
    }
}